// @ts-nocheck
import './SiteBlock.scss';

import React from 'react';
import { classnames } from '@bem-react/classnames';

import { cn } from '../../utils/bem';
import { useBreakpoints } from '@dataartdev/uikit/__internal__/cjs-src/components/Grid/useBreakpoints';

export const cnSiteBlock = cn('SiteBlock');

export const blockPropSize = ['m', 'xs', 's', 'l', 'xl', '3xxl'] as const;
export type BlockPropSize = typeof blockPropSize[number];
export const blockPropSizeDefault: BlockPropSize = blockPropSize[0];

type Breakpoint = {
  sideGap?: number | string;
  topGap?: number | string;
  bottomGap?: number | string;
};

type BreakpointsProps = {
  mobile?: Breakpoint;
  tablet?: Breakpoint;
  desktop?: Breakpoint;
};

type Props = {
  id?: string;
  children?: React.ReactNode;
  sideGap?: string;
  topGap?: string;
  bottomGap?: string;
  className?: string;
  breakpoints?: BreakpointsProps;
  useMargin?: boolean;
};

export const SiteBlock: React.FC<Props> = ({
  children,
  topGap,
  bottomGap,
  className,
  breakpoints,
  useMargin,
  id,
  sideGap,
  view,
}) => {
  const breakpointsCn = useBreakpoints(cnSiteBlock, breakpoints);

  return (
    <div
      className={classnames(
        cnSiteBlock({
          topGap,
          bottomGap,
          useMargin,
          sideGap,
          view,
        }),
        breakpointsCn,
        className
      )}
      id={id}
    >
      {children}
    </div>
  );
};
