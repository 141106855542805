import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ISingleIndustries } from '../../../features/industries/pages/SingleIndustries/models';

export const querySingleIndustries: DataQueryFunction<ISingleIndustries> = variables => {
  const { slug, locale } = variables || {};
  return httpClient.get(clearUrl(`/industries/${slug}`), {
    params: {
      lang: locale,
    },
  });
};
