import { configureStore } from '@reduxjs/toolkit';
import { layoutReducer, screenReducer } from './reducers';
import { querySearchPage } from '../network/api/searchPage';

const store = configureStore({
  reducer: {
    layout: layoutReducer,
    screen: screenReducer,
    [querySearchPage.reducerPath]: querySearchPage.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(querySearchPage.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export { store };
