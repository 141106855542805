import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ISustainabilityPage } from '@features/company/pages/models/sustainability_page';

export const querySustainabilityPage: DataQueryFunction<ISustainabilityPage> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/sustainability`), {
    params: {
      lang: locale,
    },
  });
};
