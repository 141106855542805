import './VideoCard.scss';

import React, { CSSProperties, useMemo, useState } from 'react';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { IconYouTube } from '@dataartdev/uikit/IconYouTube';
import { Button } from '@dataartdev/uikit/Button';
import { Modal } from '@dataartdev/uikit/Modal';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import { Typography } from '@dataartdev/uikit/Typography';
import { IVideo } from '@commonTypes/common';
import { IconProps, IconPropSize } from '@dataartdev/uikit/Icon';
import { ICard } from '../ContentBuilder/templates/models/CTI_28.props';
import {
  CardPropDirection,
  CardPropSize,
  cardPropSizeDefault,
  cardPropSizeDirection,
  CardPropView,
  cardPropViewDefault,
} from '../EssenceCard/models';

export interface Props extends Omit<IVideo, 'contentType'>, Omit<ICard, 'id'> {
  size?: CardPropSize;
  view?: CardPropView;
  direction?: CardPropDirection;
  bottomDescription?: string;
  linkIcon?: React.FC<IconProps>;
  onClickButton?: () => void;
  url?: string;
  descriptionSmall?: string;
  iconSize?: IconPropSize;
  style?: CSSProperties;
  linkTitle?: string;
  detailLinkTitle?: string;
}

export const cnVideoCard = cn('VideoCard');

export const VideoCard: React.FC<Props> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    ...data
  } = usePropsHandler(cnVideoCard(), props);

  const {
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    bottomDescription,
    picture,
    linkIcon = IconYouTube,
    onClickButton,
    url, // TODO: check this same props
    videoUrl, // TODO: check this props
    youTubeEmbededUrl,
    descriptionSmall,
    iconSize = 'm',
    style,
    linkTitle,
    detailLinkTitle,
    type,
    videoSectionCategory,
  } = data || {};

  let currentIcon = linkIcon; // TODO: check this place

  const tags = useMemo(
    () => (
      <>
        <div className={cnVideoCard('Tags')}>
          {hrvTags && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );

  const onClickButtonHandler = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <div
        className={cnVideoCard({
          view,
          size,
          direction,
        })}
        style={style}
      >
        {videoUrl && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div
            className={cnVideoCard('Link')}
            onClick={() => setIsModalOpen(true)}
          />
        )}

        {view !== 'text' && view !== 'text2' && picture && (
          <div className={cnVideoCard('Photo')}>
            <div className={cnVideoCard('Play')} />
            <Picture {...picture} />
          </div>
        )}

        <div className={cnVideoCard('Content')}>
          {(postDate || descriptionSmall || tagsList || title || linkTitle) && (
            <div className={cnVideoCard('Body')}>
              {view !== 'text' && view !== 'filled' && postDate && (
                <div className={cnVideoCard('Date')}>
                  <Typography.Text weight="semibold" size="xs">
                    {postDate}
                  </Typography.Text>
                </div>
              )}

              {(videoSectionCategory || type?.name) && (
                <Typography.Text
                  weight="bold"
                  transform="uppercase"
                  size="xs"
                  className={cnVideoCard('Type')}
                >
                  {videoSectionCategory || type?.name}
                </Typography.Text>
              )}

              {descriptionSmall && (
                <div className={cnVideoCard('DescriptionSmall')}>
                  <Typography.Text
                    weight="bold"
                    size="xs"
                    transform="uppercase"
                  >
                    {descriptionSmall}
                  </Typography.Text>
                </div>
              )}

              {title && (
                <div className={cnVideoCard('Title')}>
                  <Typography.Title weight="bold" size="tiny" as="h3">
                    {title}
                  </Typography.Title>
                </div>
              )}

              {view === 'text3' && tags}

              {view !== 'text' && view !== 'text3' && view !== 'filled' && tags}

              {view !== 'text' &&
                view !== 'text2' &&
                (linkTitle || detailLinkTitle) && (
                  <div className={cnVideoCard('Button')}>
                    <Button
                      view="link-secondary"
                      iconRight={currentIcon}
                      iconSize={iconSize}
                      label={detailLinkTitle ?? linkTitle}
                      onClick={onClickButton || onClickButtonHandler}
                    />
                  </div>
                )}
            </div>
          )}

          {bottomDescription && (
            <Typography.Text
              size="s"
              view="web"
              className={cnVideoCard('BottomDescription')}
            >
              {bottomDescription}
            </Typography.Text>
          )}
        </div>
      </div>
      <Modal
        className={cnVideoCard('Modal')}
        isOpen={isModalOpen}
        hasOverlay
        width="full"
        onOverlayClick={(): void => setIsModalOpen(false)}
        onEsc={(): void => setIsModalOpen(false)}
      >
        <iframe
          style={{ border: 'none' }}
          title="modal-iframe"
          src={youTubeEmbededUrl}
          width="100%"
          height="100%"
        />

        <Button
          view="ghost"
          iconRight={IconClose}
          className="Modal-Close"
          onClick={(): void => setIsModalOpen(false)}
        />
      </Modal>
    </>
  );
};
