// @ts-nocheck

export * from './authConfig';
export * from './breakpoint';
export * from './filters';
export * from './urls';
export * from './salesForceCBRoutes';

export const recaptchaKey = window.INITIAL_STATE.recaptchaKey;
export const recaptchaFormKey = window.INITIAL_STATE.recaptchaFormKey;
