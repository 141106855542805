import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { screenSelector } from '../../store';

const useScrollToHash = () => {
  const location = useLocation();
  const { isMobile, isTablet } = useSelector(screenSelector);

  useEffect(() => {
    const hash = location.hash;
    if (!hash) return;

    const id = hash.substring(1);

    const scrollToElement = () => {
      const element = document.getElementById(id);
      if (element) {
        const headerOffset = isMobile ? 64 : isTablet ? 72 : 130;

        requestAnimationFrame(() => {
          const elementPosition =
            element.getBoundingClientRect().top +
            window.pageYOffset -
            headerOffset;
          window.scrollTo({ top: elementPosition, behavior: 'smooth' });
        });
        return true;
      }
      return false;
    };

    const handleLoad = () => {
      if (!scrollToElement()) {
        const observer = new MutationObserver(() => {
          if (scrollToElement()) {
            observer.disconnect();
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
      }
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [location]);
};

export default useScrollToHash;
