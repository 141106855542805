import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from '../../../common/utils';
import { IBlogSingle } from '../../../features/blog/pages/blog_single_models';

export const queryBlogPost: DataQueryFunction<IBlogSingle> = variables => {
  const { locale, slug } = variables || {};
  return httpClient.get(clearUrl(`/blog/post/${slug}`), {
    params: {
      lang: locale,
    },
  });
};
