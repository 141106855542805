import React from 'react';
import ReactMarkdown from 'react-markdown';

interface IMessage {
  role: string;
  text: string;
}

const Message: React.FC<IMessage> = ({ role, text }) => {
  return (
    <div className={`da-chatbot-message ${role}`}>
      {text && (
        <ReactMarkdown
          children={text}
          allowDangerousHtml={false}
          linkTarget="_blank"
        />
      )}
    </div>
  );
};

export default Message;
