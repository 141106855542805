export enum CardPropSize {
  SMALL = 's',
  MEDIUM = 'm',
  LARGE = 'l',
}
export const cardPropSizeDefault = CardPropSize.SMALL;

export enum CardPropView {
  TEXT = 'text',
  TEXT2 = 'text2',
  TEXT3 = 'text3',
  FEED = 'feed',
  FILLED = 'filled',
  DEFAULT = 'default',
  SMALL_POST = 'small_post',
  SLIDER = 'slider',
}
export const cardPropViewDefault = CardPropView.TEXT;

export enum CardPropDirection {
  COLUMN = 'column',
  ROW = 'row',
}
export const cardPropSizeDirection = CardPropDirection.COLUMN;

// TODO: search all modules where use this types
