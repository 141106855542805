import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationEventFormRegister: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body } = variables;

  return httpClient.post('/event/submit', body);
};
