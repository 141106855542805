import React, { Suspense } from 'react';
import {
  Redirect,
  matchPath,
  withRouter,
  RouteComponentProps,
  Route,
  generatePath,
  match as IMatch,
} from 'react-router-dom';
import { PATH } from 'router';
import { RouteConfig } from './models';
import { layoutMap } from 'common/layouts';
import { generate } from 'shortid';
import { useSelector } from 'react-redux';
import { layoutKeySelector } from 'store';
import { ErrorPage } from 'common/components';
import { Loader } from '../common/components/Loader/Loader';

export interface RouteParams {
  lang?: string;
  slug?: string;
  parentSlug?: string;
  inner_page?: string;
  inner_page_secondary?: string;
}

export interface PageState {
  is404: boolean;
  is500: boolean;
  is451: boolean;
  is400: boolean;
}

type Props = RouteComponentProps<RouteParams, any, PageState> & {
  routes: RouteConfig[];
};

const findMatchedRoute = (routes: RouteConfig[], pathname: string) => {
  let match = null;
  const route = routes.find(
    r =>
      (match = matchPath<RouteParams>(pathname, {
        path: r.path,
        exact: r.exact,
      }))
  );
  return { match, route };
};

export const SwitchRoutes = withRouter(
  ({ routes, location, history }: Props) => {
    const { pathname, state } = location;
    const { is404, is500, is451, is400 } = state || {};
    const layoutKey = useSelector(layoutKeySelector);

    const { match, route: matchedRoute } = findMatchedRoute(routes, pathname);
    if (!matchedRoute || !match) return null;

    const { layout, redirect, component: Component, update } = matchedRoute;
    const LayoutComponent = layoutMap[layout].component;
    const layoutProps = layoutMap[layout].props || {};

    const routeProps = {
      history,
      location,
      match: match as IMatch<RouteParams>,
    };
    const routeKey =
      update === 'always' ? generate() : update === 'path' ? pathname : '';

    const renderRoutes = () => {
      if (is451) return <ErrorPage {...routeProps} errorCode={451} />;
      if (is500) return <ErrorPage {...routeProps} errorCode={500} />;
      if (is404 && pathname.includes('company/events/'))
        return <Redirect to={generatePath(PATH.EVENTS)} />;
      if (is404) return <ErrorPage {...routeProps} errorCode={404} />;
      if (is400) return <ErrorPage {...routeProps} errorCode={400} />;
      if (redirect) return <Redirect to={redirect} />;
    };

    const result = renderRoutes();
    if (result) return result;

    return (
      <LayoutComponent key={layoutKey} {...layoutProps} {...routeProps}>
        {Component && (
          <Suspense fallback={<Loader />}>
            <Route
              key={routeKey}
              component={Component}
              exact={matchedRoute.exact}
              path={matchedRoute.path}
            />
          </Suspense>
        )}
      </LayoutComponent>
    );
  }
);
