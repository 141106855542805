import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ISingleService } from '@features/services/pages/models';

export const querySingleService: DataQueryFunction<ISingleService> = variables => {
  const { slug, locale } = variables || {};

  return httpClient.get(clearUrl(`/services/${slug}`), {
    params: {
      lang: locale,
    },
  });
};
