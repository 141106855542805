import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationMainAllForm: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body }: any = variables;

  const form_data = new FormData();

  Object.keys(body).forEach(key => form_data.append(key, body[key]));

  return httpClient.post('/formbuilder/submit', form_data);
};
