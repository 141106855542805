import { createReducer } from '@reduxjs/toolkit';
import { BREAKPOINT } from 'common/constants';
import { generate } from 'shortid';
import {
  setDictionaries,
  setScreenWidth,
  setMenu,
  setLanguages,
  setInitialData,
  setPageId,
  setMetaData,
  setModalData,
  setModal,
  setHeaderInverted,
  setHeaderTransparent,
  setMetaTitlePage,
  setSearchModalVisible,
  setSearchRequest,
  setFormModal,
  setContactButtonVisible,
} from './actions';
import { ILayoutState, IScreenState } from './models';

export const layoutReducer = createReducer<ILayoutState>(
  {
    key: generate(),
    pageId: null,
    forceActivation: false,
    headerInverted: false,
    headerTransparent: '',
    searchModalVisible: false,
    contactButtonVisible: {
      isVisible: false,
      isInitiallyChecked: true,
    },
    metaTitlePage: null,
    dictionaries: null,
    menuItems: null,
    menu: null,
    footer: null,
    languages: null,
    modalData: null,
    error404: null,
    error500: null,
    modal: null,
    siteMap: null,
  },
  {
    [setDictionaries.type]: (state, action) => ({
      ...state,
      dictionaries: Object.assign(
        {},
        state.dictionaries,
        action.payload.companySite
      ),
    }),
    [setPageId.type]: (state, action) => ({
      ...state,
      pageId: action.payload,
    }),
    [setMenu.type]: (state, action) => ({
      ...state,
      menu: action.payload,
    }),
    [setLanguages.type]: (state, action) => ({
      ...state,
      languages: action.payload,
    }),
    [setInitialData.type]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [setMetaData.type]: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    [setModalData.type]: (state, action) => ({
      ...state,
      modalData: action.payload,
    }),
    [setModal.type]: (state, action) => ({
      ...state,
      modal: action.payload,
    }),
    [setFormModal.type]: (state, action) => ({
      ...state,
      formModal: action.payload,
    }),
    [setContactButtonVisible.type]: (state, action) => ({
      ...state,
      contactButtonVisible: action.payload,
    }),
    [setHeaderInverted.type]: (state, action) => ({
      ...state,
      headerInverted: action.payload,
    }),
    [setHeaderTransparent.type]: (state, action) => ({
      ...state,
      headerTransparent: action.payload,
    }),
    [setMetaTitlePage.type]: (state, action) => ({
      ...state,
      metaTitlePage: action.payload,
    }),
    [setSearchRequest.type]: (state, action) => ({
      ...state,
      searchRequest: action.payload,
    }),
    [setSearchModalVisible.type]: (state, action) => ({
      ...state,
      searchModalVisible: action.payload,
    }),
  }
);

export const screenReducer = createReducer<IScreenState>(
  {
    width: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isDesktopXL: false,
  },
  {
    [setScreenWidth.type]: (_, action) => ({
      width: action.payload,
      isMobile: action.payload <= BREAKPOINT.MOBILE,
      isTablet:
        action.payload > BREAKPOINT.MOBILE &&
        action.payload <= BREAKPOINT.TABLET,
      isDesktop:
        action.payload > BREAKPOINT.TABLET &&
        action.payload <= BREAKPOINT.DESKTOP,
      isDesktopXL: action.payload > BREAKPOINT.DESKTOP,
    }),
  }
);
