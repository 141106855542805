import React from 'react';
import './CookiesPopup.scss';
import { dictionariesSelector } from 'store';
import { useSelector } from 'react-redux';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { cn } from 'ui/utils/bem';

const cnCookiesPopup = cn('CookiesPopup');

type Props = {
  onAcceptCookieHandler: any;
};
export const CookiesPopup: React.FC<Props> = props => {
  const { companySiteCookie }: any = useSelector(dictionariesSelector);

  return (
    <div className={cnCookiesPopup()}>
      <div className="container">
        <Grid
          breakpoints={{
            desktop: { cols: 12, colGap: 'xl' },
            tablet: { cols: 8, colGap: 'xl' },
            mobile: { cols: 4, colGap: 'xl' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 8 },
              tablet: { col: 5 },
              mobile: { col: 4 },
            }}
          >
            <Typography.Text parse>
              {companySiteCookie?.companySiteCookieDescription}
            </Typography.Text>
          </GridItem>
          <GridItem
            breakpoints={{
              desktop: { col: 4 },
              tablet: { col: 3 },
              mobile: { col: 4 },
            }}
            className={cnCookiesPopup('Buttons')}
          >
            <Button
              onClick={() =>
                props.onAcceptCookieHandler('cookiesPopupNeeded', '1')
              }
              label={companySiteCookie?.companySiteCookieButtonText || 'Accept'}
            />
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};
