import { useState, useEffect } from 'react';
import { DataQueryFunction } from 'network';
import {
  DataQueryOptions,
  DataQueryVariables,
  Pagination,
  QueryFilter,
} from './models';
import { AxiosError } from 'axios';
import { clearFalsyProps } from 'common/utils';

export const useDataPagination = (
  query: DataQueryFunction | null,
  variables: DataQueryVariables = null,
  options?: DataQueryOptions
) => {
  const { filter: varFilter, pagination: varPagination } = variables || {};
  const { page: varPage } = varPagination || {};
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [params, setParams] = useState<{
    pagination: {
      page: number;
    };
    filter: QueryFilter;
  }>({
    pagination: {
      page: varPage || 1,
    },
    filter: varFilter || {},
  });

  const { skip = null } = options || {};

  const fetchData = () => {
    if (skip || !query) {
      setLoading(false);
      return;
    }
    setLoading(true);
    query({
      ...variables,
      ...params,
    })
      .then(res => {
        setResponse(res);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setResponse(null);
        setLoading(false);
      });
  };

  const refetch = () => {
    if (!loading) fetchData();
  };

  const updatePagination = (newPagination: Pagination) => {
    setParams(state => ({
      ...state,
      pagination: { ...state.pagination, ...clearFalsyProps(newPagination) },
    }));
    setLoading(true);
  };

  const updateFilter = (newFilter: QueryFilter) => {
    setParams(state => ({
      pagination: {
        ...state.pagination,
        page: 1,
      },
      filter: { ...state.filter, ...newFilter },
    }));

    setLoading(true);
  };

  useEffect(() => {
    fetchData();
  }, [skip, params]); // eslint-disable-line

  return {
    response,
    data: (response && response.data) || null,
    loading,
    error,
    pagination: params.pagination,
    // pagination: {
    //   ...params.pagination,
    //   totals: (response && response.data && response.data.totals) || 0,
    // },
    filter: params.filter,
    totals: (response && response.data && response.data.totalPages) || 0,
    refetch,
    updatePagination,
    updateFilter,
  };
};
