// @ts-nochecks
import './Header.scss';
import React, { useState } from 'react';
import { cn } from '../../utils/bem';
import { PropsWithJsxAttributes } from '../../utils/types/PropsWithJsxAttributes';
import { useSelector } from 'react-redux';
import { headerTransparentSelector } from '../../../store';

export type HeaderProps = PropsWithJsxAttributes<
  {
    leftSide?: React.ReactNode;
    rightSide?: React.ReactNode;
    children?: never;
    modifier: string;
  },
  'header'
>;

export const cnHeader = cn('Header');

export function Header(props: HeaderProps): React.ReactElement {
  const { leftSide, rightSide, modifier, ...otherProps } = props;

  return (
    <header {...otherProps} className={cnHeader({ modifier })}>
      <div className="container">
        {leftSide && <div className={cnHeader('LeftSide')}>{leftSide}</div>}
        {rightSide && <div className={cnHeader('RightSide')}>{rightSide}</div>}
      </div>
    </header>
  );
}

export { HeaderLogo } from './Logo/HeaderLogo';
export { HeaderMenu } from './Menu/HeaderMenu';
export { HeaderMenuMobile } from './Menu/HeaderMenuMobile';
export { HeaderModule } from './Module/HeaderModule';
