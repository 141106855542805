import { QueryFilter } from '../hooks';

export function clearFalsyProps(filter: QueryFilter | null = {}): QueryFilter {
  if (!filter) return {};
  return Object.keys(filter).reduce((acc: Record<string, unknown>, key) => {
    const value = filter[key as keyof QueryFilter];
    if (value !== null && value !== undefined && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {} as QueryFilter);
}
