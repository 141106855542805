import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationLocationsCVForm: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body = {} } = variables;

  const form_data = new FormData();

  interface ISkill {
    id: number;
    parentId: number;
    title: string;
  }

  const appendSkills = (skillsKey: string, arrayName: string) => {
    const key = body[skillsKey];
    if (key && Array.isArray(key)) {
      key.forEach((skill: ISkill) => {
        form_data.append(`${arrayName}[]`, skill.id.toString());
      });
    } else if (key && Object.keys(key).length > 0) {
      form_data.append(`${skillsKey}`, key.id.toString());
    }
  };

  Object.keys(body).forEach(key => {
    switch (key) {
      case 'skills':
        appendSkills('skills', 'skills');
        break;
      case 'secondarySkills':
        appendSkills('secondarySkills', 'secondarySkills');
        break;
      case 'file':
        if (body[key] instanceof File) {
          form_data.append(key, body[key]);
        }
        break;
      default:
        form_data.append(key, body[key]);
    }
  });

  return httpClient.post('/contact-us/cv/submit', form_data);
};
