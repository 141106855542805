/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './DefaultCard.scss';

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import * as Typography from '@dataartdev/uikit/Typography';

export const cardPropSize = ['s', 'm', 'l'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[0];

export const cardPropView = [
  'feed',
  'filled',
  'small_post',
  'slider',
  'text',
  'text2',
] as const;

export type CardPropView = typeof cardPropView[number];
export const cardPropViewDefault: CardPropView = cardPropView[0];

export const cardPropDirection = ['column', 'row'] as const;
export type CardPropDirection = typeof cardPropDirection[number];
export const cardPropSizeDirection: CardPropDirection = cardPropDirection[0];

export type Props = {
  size?: CardPropSize;
  view?: CardPropView;
  direction?: CardPropDirection;
};

const sizeMap: Record<Props, Typography.TitleProps> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const cnDefaultCard = cn('DefaultCard');

export const DefaultCard: React.FC<Props> = props => {
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    ...data
  } = usePropsHandler(cnDefaultCard(), props);

  const {
    contentType,
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    picture,
    linkIcon = IconArrowRight,
    onClickButton,
    url,
    path,
    descriptionSmall,
    text,
    iconSize = 's',
    style,
    linkTitle,
    bannerPicture,
    redirectTo,
    fullUrl,
  } = data || {};

  let defaultPicture = null;
  let currentIcon = linkIcon;

  switch (contentType) {
    case 6:
      defaultPicture = bannerPicture;
      break;

    default:
      defaultPicture = picture;
      break;
  }

  const tags = useMemo(
    () => (
      <>
        <div className={cnDefaultCard('Tags')}>
          {hrvTags && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnDefaultCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnDefaultCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );

  const onClickButtonHandler = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div
      className={cnDefaultCard({
        view,
        size,
        direction,
      })}
      style={style}
    >
      {path && <NavLink className={cnDefaultCard('Link')} to={path} />}

      {(redirectTo || fullUrl) && (
        <a
          href={redirectTo || fullUrl}
          className={cnDefaultCard('Link')}
          target="_blank"
          rel="noreferrer noopener"
        />
      )}

      {view !== 'text' && view !== 'text2' && defaultPicture && (
        <div className={cnDefaultCard('Photo')}>
          <Picture {...defaultPicture} />
        </div>
      )}

      <div className={cnDefaultCard('Body')}>
        {view !== 'text' && postDate && (
          <div className={cnDefaultCard('Date')}>
            <Typography.Typography.Text weight="semibold" size="xs">
              {postDate}
            </Typography.Typography.Text>
          </div>
        )}

        {descriptionSmall && (
          <div className={cnDefaultCard('DescriptionSmall')}>
            <Typography.Typography.Text
              weight="semibold"
              size="xs"
              transform="uppercase"
            >
              {descriptionSmall}
            </Typography.Typography.Text>
          </div>
        )}

        <div className={cnDefaultCard('Title')}>
          <Typography.Typography.Title
            weight="bold"
            size={getSizeByMap(sizeMap, size)}
            as="h3"
          >
            {title}
          </Typography.Typography.Title>
        </div>

        {text && (
          <div className={cnDefaultCard('Text')}>
            <Typography.Typography.Text>{text}</Typography.Typography.Text>
          </div>
        )}

        {view !== 'text' && view !== 'filled' && tags}

        {view !== 'text' && view !== 'text2' && linkTitle && (
          <div
            style={{
              pointerEvents: 'none',
            }}
            className={cnDefaultCard('Button')}
          >
            <Button
              view="link-secondary"
              iconRight={currentIcon}
              iconSize={iconSize}
              label={linkTitle}
              onClick={onClickButton ? onClickButton : onClickButtonHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};
