import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ISingleInnerIndustries } from '@features/industries/pages/SingleInnerIndustries/models';

export const querySingleInnerIndustries: DataQueryFunction<ISingleInnerIndustries> = variables => {
  const { slug, slug2, locale } = variables || {};

  return httpClient.get(
    clearUrl(`/industries/squad-or-offering/${slug}?parentSlug=${slug2}`),
    {
      params: {
        lang: locale,
      },
    }
  );
};
