import { createAction } from '@reduxjs/toolkit';

export const setDictionaries = createAction<any>('SET_DICTIONARIES');
export const setMenu = createAction<any>('SET_MENU');
export const setLanguages = createAction<any>('SET_LANGUAGES');
export const setInitialData = createAction<any>('SET_INITIAL_DATA');
export const setPageId = createAction<number>('SET_PAGE_ID');
export const setMetaData = createAction<number>('SET_META_DATA');
export const setModalData = createAction<number | null>('SET_MODAL_PAGE');
export const setModal = createAction<any>('SET_MODAL');
export const setMetaTitlePage = createAction<string | null>(
  'SET_META_TITLE_PAGE'
);
export const setHeaderInverted = createAction<boolean>('SET_HEADER_INVERTED');
export const setHeaderTransparent = createAction<string>(
  'SET_HEADER_TRANSPARENT'
);
export const setFormModal = createAction<boolean>('SET_FORM_MODAL');
export const setContactButtonVisible = createAction<{
  isVisible: boolean;
  isInitiallyChecked: boolean;
}>('SET_CONTACT_BUTTON_VISIBLE');
export const setSearchRequest = createAction<string>('SET_SEARCH_REQUEST');
export const setSearchModalVisible = createAction<any>(
  'SET_SEARCH_MODAL_VISIBLE'
);

//SCREEN
export const setScreenWidth = createAction<number>('SET_SCREEN_WIDTH');
