import React from 'react';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconProps } from '@dataartdev/uikit/Icon';

type T_CTA = {
  title: string;
  link: string;
  isCTA: boolean;
};
type T_EXTERNAL_LINK = {
  as: keyof React.JSX.IntrinsicElements;
  href: string;
  target: string;
  label: string;
  iconRight: React.FC<IconProps>;
};
type T_REACT_LINK = {
  isLink: boolean;
  to: string;
  target: string;
  label: string;
  iconRight: React.FC<IconProps>;
};

export type ButtonTypes = T_CTA | T_EXTERNAL_LINK | T_REACT_LINK;

export const prepareButtonAttrs = (
  link?: string,
  linkTitle?: string,
  targetBlank: boolean = false
): ButtonTypes | {} => {
  if (!link || !linkTitle) return {};

  const isCTA = link.includes('#');
  const isExternal = link.includes('http');
  const target = targetBlank ? '_blank' : '_self';

  if (isCTA) {
    return {
      title: linkTitle,
      link: link.replace('#', ''),
      isCTA,
    };
  }

  if (isExternal) {
    return {
      as: 'a',
      href: link,
      target,
      label: linkTitle,
      iconRight: IconOpenInNew,
    };
  }

  return {
    isLink: true,
    to: link || '/404',
    target,
    label: linkTitle,
    iconRight: IconArrowRight,
  };
};

export const hasLabel = (obj: any): obj is T_EXTERNAL_LINK | T_REACT_LINK => {
  return 'label' in obj;
};

export const isExternal = (link: string | undefined) =>
  !!link && link.includes('http');
