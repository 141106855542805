import './ErrorPage.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { BackgroundDecor } from '@dataartdev/uikit/BackgroundDecor';
import { Tooltip } from '@dataartdev/uikit/Tooltip';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { SiteBlock } from '../SiteBlock/SiteBlock';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cn } from 'ui/utils/bem';

import PictureLeft from 'assets/img/error_left_lil.svg';
import PictureRight from 'assets/img/error_right_lil.svg';

import DecorEn from './242-en@1x.gif';
import DecorEn2x from './242-en@2x.gif';

const cnPage = cn('ErrorPage');

type Props = RouteComponentProps & {
  errorCode: 404 | 500 | 451 | 400;
};

export const ErrorPage: React.FC<Props> = ({
  errorCode,
  history,
  location,
}) => {
  const { error404, error500 } = window.INITIAL_STATE;

  const {
    description,
    button,
    dialoge,
    relevantContent,
    relevantSectionTitle,
    subtitle,
    title,
  } =
    (errorCode === 500 ? error500 : errorCode === 404 ? error404 : null) || {};

  return (
    <div className={cnPage()}>
      <BackgroundDecor view="left-triangle-down" />
      <div className="container">
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl' },
            tablet: { cols: 8, gap: 'm' },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 5, colStart: 2 },
              tablet: { col: 4 },
            }}
          >
            <Typography.Text
              size="xs"
              weight="bold"
              transform="uppercase"
              className={cnPage('Title')}
              as="h1"
            >
              {subtitle || errorCode + ' Error'}
            </Typography.Text>
            <Typography.Title size="lg" className={cnPage('MainTitle')} as="h2">
              {title || 'Error title'}
            </Typography.Title>
            <Typography.Text className={cnPage('Text')}>
              {description ||
                'You may have typed the address (URL) incorrectly. Please check to make sure you have the right spelling. In the meantime, see the relaited links to our most popular content.'}
            </Typography.Text>
            <div className={cnPage('Button')}>
              <Button
                label={button?.title || 'To Home Page'}
                isLink
                to={button?.link || '/'}
                view="secondary"
                iconRight={IconArrowRight}
              />
            </div>

            {relevantContent?.length > 0 && (
              <div className={cnPage('Content')}>
                {relevantSectionTitle && (
                  <Typography.Text
                    size="xs"
                    weight="bold"
                    transform="uppercase"
                    className={cnPage('Content-Title')}
                  >
                    {relevantSectionTitle}
                  </Typography.Text>
                )}
                {relevantContent.map((link, index) => (
                  <SiteBlock useMargin topGap="s">
                    <Button
                      key={link.link + index}
                      className={cnPage('Content-Button')}
                      view="link-primary"
                      isLink={!link.targetBlank}
                      to={!link.targetBlank && link.link}
                      href={link.targetBlank && link.link}
                      target={link.targetBlank ? '_blank' : null}
                      iconRight={IconArrowRight}
                      label={link.title}
                    />
                  </SiteBlock>
                ))}
              </div>
            )}
          </GridItem>

          {dialoge && (
            <GridItem
              breakpoints={{
                desktop: { col: 4, colStart: 8 },
                tablet: { col: 4 },
                mobile: { col: 1, gap: 'xxl' },
              }}
            >
              <div className={cnPage('Dialog2')}>
                <img src={DecorEn} srcSet={DecorEn2x + ' 2x'} alt="decor" />
              </div>
              <div className={cnPage('Dialog')} hidden>
                <div className={cnPage('Dialog-Chat')}>
                  {dialoge.map((item, index) => (
                    <div
                      className={cnPage('Dialog-Chat-Item', {
                        right: (index & 1) === 0,
                      })}
                      key={item + index}
                    >
                      <Tooltip
                        staticPosition
                        directionPosition={
                          (index & 1) === 0 ? 'upStartRight' : 'upStartLeft'
                        }
                        style={{ opacity: index > 0 ? 1 - 0.4 : 1 }}
                      >
                        {item}
                      </Tooltip>
                    </div>
                  ))}
                </div>
                <div className={cnPage('Dialog-Persons')}>
                  <div
                    className={cnPage('Dialog-Persons-Item', { left: true })}
                  >
                    <img src={PictureLeft} alt="alt" />
                  </div>
                  <div
                    className={cnPage('Dialog-Persons-Item', { right: true })}
                  >
                    <img src={PictureRight} alt="alt" />
                  </div>
                </div>
              </div>
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  );
};
