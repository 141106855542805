/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck
import './HeaderMenu.scss';

import React from 'react';
import { useHistory } from 'react-router-dom';

import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
import { useTheme } from '@dataartdev/uikit/Theme';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { PortalWithTheme } from '@dataartdev/uikit/PortalWithTheme';
import { Picture } from '@dataartdev/uikit/Picture';
import TagManager from 'react-gtm-module';

export const cnHeaderMenu = cn('HeaderMenu');

export type Item = {
  title?: string;
  nodeUrl?: string;
  target?: string;
  externalLink?: boolean;
  onHeaderMenuHandler?: React.EventHandler<React.MouseEvent>;
  children?: never;
  container?: HTMLDivElement | undefined;
};

type MenuProps = PropsWithJsxAttributes<
  {
    items: Item[];
    onHover?: any;
    onBlur?: any;
  },
  'nav'
>;

export const HeaderMenu: React.FC<MenuProps> = ({
  items,
  className,
  onHover,
  onBlur,
  container = window.document.body,
  hoverState,
  inverted,
  onHeaderMenuHandler,
}) => {
  const { theme } = useTheme();
  const [hovered, setHovered] = React.useState(false);
  const timer = React.useRef(null);
  const history = useHistory();

  const mouseEnterHandler = () => {
    if (onHover) onHover();

    setHovered(true);

    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const mouseLeaveHandler = () => {
    timer.current = setTimeout(() => {
      setHovered(false);
      if (onBlur) onBlur();
    }, 500);
  };

  const setParams = ({ externalLink, targetBlank, nodeUrl }) => {
    return {
      isLink: !!nodeUrl,
      as: !!nodeUrl ? 'link' : 'a',
      to: nodeUrl ? nodeUrl ?? '/404' : null,
      href: externalLink,
      target: targetBlank ? '_blank' : '_self',
    };
  };

  const servicesHandler = (title: string, nodeUrl: string) => async (
    e: MouseEvent
  ) => {
    if (!nodeUrl?.includes('services')) return;
    const service = title.trim().toLowerCase();
    let dataLayer = {
      dataLayer: {
        event: 'click_services',
        services:
          service === 'services'
            ? 'click_all_services'
            : `click_${service.split(' ').join('_')}`,
      },
    };
    try {
      await TagManager.dataLayer(dataLayer);

      if (nodeUrl.includes('http')) {
        window.location.href = nodeUrl;
      } else {
        history.push(nodeUrl);
      }
    } catch (error) {
      console.error('Error with TagManager dataLayer:', error);
    }
  };

  return (
    <nav className={cnHeaderMenu({ hovered }, [className])}>
      <ul className={cnHeaderMenu('List')}>
        {items.length > 0 &&
          items.map(
            (
              {
                type,
                node: { title, nodeUrl, externalLink, label, targetBlank },
                nodes,
                columns,
                sideColumn,
                additionalNodes,
              },
              index
            ) => {
              const tagWrapperAttrs = setParams({
                externalLink,
                nodeUrl,
                targetBlank,
              });
              return (
                <li
                  className={`HeaderMenu-Item ${(nodes?.length ||
                    columns?.length) &&
                    'HeaderMenu-Item_subItems'}`}
                  key={title}
                  onMouseEnter={() => mouseEnterHandler(nodes?.length)}
                  onMouseLeave={() => mouseLeaveHandler()}
                  onClick={() => onHeaderMenuHandler()}
                >
                  <Button
                    onClick={servicesHandler(title, nodeUrl)}
                    inverted={inverted}
                    className={cnHeaderMenu('Link', {
                      inverted: !!label?.isInverted,
                    })}
                    view="transparent"
                    label={title}
                    size="s"
                    {...tagWrapperAttrs}
                    badge={
                      label && {
                        text: label.value,
                        inverted: !!label.isInverted,
                        color: label.labelColor,
                      }
                    }
                  />
                  {(nodes || columns) && (
                    <div className={cnHeaderMenu('SubList')}>
                      <div className={cnHeaderMenu('SubList-Inner')}>
                        {type === 0 && (
                          <div className={cnHeaderMenu('Columns')}>
                            <div className={cnHeaderMenu('Columns-Primary')}>
                              <div className={cnHeaderMenu('Column Wide')}>
                                {nodes?.map(
                                  ({
                                    externalLink,
                                    label,
                                    logo,
                                    nodeUrl,
                                    targetBlank,
                                    title,
                                  }) => {
                                    const tagWrapperAttrs = setParams({
                                      externalLink,
                                      nodeUrl,
                                      targetBlank,
                                    });
                                    return (
                                      <Button
                                        key={title}
                                        className={cnHeaderMenu('SubLink', {
                                          inverted: !!label?.isInverted,
                                        })}
                                        view="transparent"
                                        {...tagWrapperAttrs}
                                        badge={
                                          label && {
                                            text: label.value,
                                            inverted: !!label.isInverted,
                                            color: label.labelColor,
                                          }
                                        }
                                      >
                                        {title}
                                      </Button>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {type === 1 && columns && (
                          <div className={cnHeaderMenu('Columns')}>
                            <div className={cnHeaderMenu('Columns-Primary')}>
                              {columns.map(({ title, nodes }) => (
                                <div
                                  className={cnHeaderMenu('Column')}
                                  key={title}
                                >
                                  <Typography.Title size="tiny" as="span">
                                    {title}
                                  </Typography.Title>
                                  {nodes.map(
                                    ({
                                      title,
                                      nodeUrl,
                                      externalLink,
                                      targetBlank,
                                      logo,
                                      label,
                                    }) => {
                                      const tagWrapperAttrs = setParams({
                                        externalLink,
                                        nodeUrl,
                                        targetBlank,
                                      });
                                      return (
                                        <Button
                                          key={title}
                                          className={cnHeaderMenu('SubLink', {
                                            inverted: !!label?.isInverted,
                                          })}
                                          {...tagWrapperAttrs}
                                          view="transparent"
                                          badge={
                                            label && {
                                              text: label.value,
                                              inverted: !!label.isInverted,
                                              color: label.labelColor,
                                            }
                                          }
                                          onClick={servicesHandler(
                                            title,
                                            nodeUrl
                                          )}
                                        >
                                          {title}
                                        </Button>
                                      );
                                    }
                                  )}
                                </div>
                              ))}
                              {additionalNodes && (
                                <div className={cnHeaderMenu('Column Bottom')}>
                                  {additionalNodes.map(additionalNode => {
                                    const {
                                      externalLink,
                                      label,
                                      nodeUrl,
                                      targetBlank,
                                      title,
                                    } = additionalNode;
                                    const tagWrapperAttrs = setParams({
                                      externalLink,
                                      nodeUrl,
                                      targetBlank,
                                    });
                                    return (
                                      <Button
                                        key={title}
                                        onClick={servicesHandler(
                                          title,
                                          nodeUrl
                                        )}
                                        className={cnHeaderMenu('SubLink', {
                                          inverted: !!label?.isInverted,
                                        })}
                                        view="transparent"
                                        size="s"
                                        {...tagWrapperAttrs}
                                        badge={
                                          label && {
                                            text: label.value,
                                            inverted: !!label.isInverted,
                                            color: label.labelColor,
                                          }
                                        }
                                      >
                                        {title}
                                      </Button>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                            {sideColumn && (
                              <div
                                className={cnHeaderMenu('Columns-Secondary')}
                              >
                                <Typography.Title size="tiny" as="span">
                                  {sideColumn.title}
                                </Typography.Title>
                                {sideColumn.nodes.map(node => {
                                  const {
                                    title,
                                    nodeUrl,
                                    externalLink,
                                    targetBlank,
                                    logo,
                                    label,
                                  } = node || {};
                                  const tagWrapperAttrs = setParams({
                                    externalLink,
                                    nodeUrl,
                                    targetBlank,
                                  });
                                  return (
                                    <Button
                                      key={title}
                                      className={cnHeaderMenu('Partner', {
                                        inverted: !!label?.isInverted,
                                      })}
                                      {...tagWrapperAttrs}
                                      view="transparent"
                                      label={title}
                                      iconRight={IconOpenInNew}
                                      iconLeft={() =>
                                        logo && <Picture {...logo} />
                                      }
                                    ></Button>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            }
          )}
      </ul>

      {hoverState && (
        <PortalWithTheme
          preset={theme}
          container={container}
          className={cnHeaderMenu('Overlay')}
        >
          <div className={cnHeaderMenu('Backdrop')} aria-label="Overlay" />
        </PortalWithTheme>
      )}
    </nav>
  );
};
