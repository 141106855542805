import './WebinarForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { mutationWebinarForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { cnModalPage } from '../ModalPage';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { usePagePath } from '../../../hooks/usePagePath';
import { useFormik } from 'formik';
import { TModalData } from '../models';
import sbjs from 'sourcebuster';

sbjs.init();

interface Props {
  onSubmitForm: (val: boolean) => void;
  modalData: TModalData;
}

export const WebinarForm: React.FC<Props> = ({ onSubmitForm, modalData }) => {
  const { pageId } = useSelector(layoutSelector) || {};
  const { companySiteForm } = useSelector(dictionariesSelector) || {};

  const {
    companySiteFormWebinarSuccessText,
    companySiteFormWebinarSubscribePolicy,
    companySiteFormWebinarRequiredField,
    companySiteFormWebinarButton,
    companySiteFormWebinarSuccessTitle,
    companySiteFormWebinarTrackingID,
    companySiteFormWebinarName,
    companySiteFormWebinarCompany,
    companySiteFormWebinarEmail: { companySiteFormWebinarEmailError = '' } = {},
  } = companySiteForm?.companySiteFormWebinar || {};
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const [successForm, setSuccessForm] = useState<boolean>(false);

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = companySiteFormWebinarTrackingID || '';

  const initialValues = {
    email: '',
    name: '',
    company: '',
    privacyAgreement: false,
  };
  const schema = yup.object({
    email: yup
      .string()
      .email(companySiteFormWebinarEmailError)
      .required(companySiteFormWebinarRequiredField)
      .nullable(),
    name: yup
      .string()
      .min(3)
      .nullable(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });
  const {
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, actions) => {
      try {
        const token = await reCaptchaRef.current?.executeAsync();
        await mutationWebinarForm({
          body: {
            ...values,
            pageId,
            [`${recaptchaFormKey}`]: token,
            cameFrom: cameFrom,
            trackingId: submitButtonID,
          },
        });
        onSubmitForm(true);
        setSuccessForm(true);
        actions.resetForm();

        TagManager.dataLayer({
          dataLayer: {
            event: 'send_form',
            form_name: 'subscribe_form',
            page_path: pagePath,
            label: values.email,
            tags: 'not set',
          },
        });
      } catch (error) {
        error instanceof Error
          ? console.error(error.message)
          : console.error(error);
      }
    },
  });

  return (
    <div className={cnModalPage({ webinar: true })}>
      <Typography.Text
        size="xs"
        weight="bold"
        transform="uppercase"
        className={cnModalPage('Title')}
      >
        {modalData.title}
      </Typography.Text>
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: '6xl' },
          tablet: { cols: 1, gap: 'xl' },
          mobile: { cols: 1, gap: 'xl' },
        }}
      >
        <GridItem breakpoints={{ desktop: { col: 5 } }}>
          {'subtitle' in modalData && modalData.subtitle && (
            <Typography.Title size="md" className={cnModalPage('Title')}>
              {modalData.subtitle}
            </Typography.Title>
          )}
          {modalData.text && (
            <Typography.Text className={cnModalPage('Text')}>
              {modalData.text}
            </Typography.Text>
          )}
          {modalData.picture && !Array.isArray(modalData.picture) && (
            <Picture {...modalData.picture} />
          )}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: 7 } }}>
          <form className={cnModalPage('Body')} onSubmit={handleSubmit}>
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'm' },
                tablet: { cols: 8, gap: 'm' },
                mobile: { cols: 1, gap: 'm' },
              }}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="name"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.name}
                  label={companySiteFormWebinarName}
                  view="outline"
                  width="full"
                  caption={
                    touched.name && errors.name ? errors.name : undefined
                  }
                  status={touched.name && !!errors.name ? 'alert' : undefined}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="email"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.email}
                  label="E-mail"
                  view="outline"
                  width="full"
                  required
                  caption={
                    touched.email && errors.email ? errors.email : undefined
                  }
                  status={touched.email && !!errors.email ? 'alert' : undefined}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="company"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.company}
                  label={companySiteFormWebinarCompany}
                  view="outline"
                  width="full"
                  caption={
                    touched.company && errors.company
                      ? errors.company
                      : undefined
                  }
                  status={
                    touched.company && !!errors.company ? 'alert' : undefined
                  }
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
                className={cnModalPage('Bottom')}
              >
                <Checkbox
                  required
                  label={companySiteFormWebinarSubscribePolicy}
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  name="privacyAgreement"
                  checked={values.privacyAgreement}
                />
                <div className={cnModalPage('Button')}>
                  <Button
                    id={submitButtonID}
                    iconRight={IconArrowRight}
                    disabled={
                      isSubmitting || !dirty || !!Object.keys(errors).length
                    }
                    label={companySiteFormWebinarButton}
                    type="submit"
                  />
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={recaptchaKey}
                    size="invisible"
                  />
                </div>
              </GridItem>
            </Grid>
          </form>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          {companySiteFormWebinarSuccessTitle && (
            <Typography.Title size="md" className={cnModalPage('Title')}>
              {companySiteFormWebinarSuccessTitle}
            </Typography.Title>
          )}
          {companySiteFormWebinarSuccessText && (
            <Typography.Text className={cnModalPage('SubTitle')}>
              {companySiteFormWebinarSuccessText}
            </Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};
