import './Form.scss';

import React from 'react';
import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
} from 'formik';

import { cn } from 'ui/utils/bem';
import { Modal } from '@dataartdev/uikit/Modal';
import { Typography } from '@dataartdev/uikit/Typography';
import { SiteBlock } from '../SiteBlock/SiteBlock';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';
import { Button } from '@dataartdev/uikit/Button';
import { IconDownload } from '@dataartdev/uikit/IconDownload';
import { IFormSuccess } from './models';

interface FormProps<T> extends FormikConfig<T> {
  children: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode;
}

interface FormBodyProps extends React.HTMLAttributes<HTMLFormElement> {}

export const cnFormSuccess = cn('FormSuccess');

export const Form: React.FC<FormProps<FormikValues>> = props => {
  return <Formik {...props}>{props.children}</Formik>;
};

export const FormBody: React.FC<FormBodyProps> = props => {
  const { children, ...rest } = props;
  return <FormikForm {...rest}>{children}</FormikForm>;
};

export const FormSuccess: React.FC<IFormSuccess> = ({
  title,
  text,
  view = 'overlay',
  topGap = '2xl',
  bottomGap = '3xl',
  sideGap = 'xl',
  titleSize = 'tiny',
  textSize,
  modal,
  link,
}) => {
  const buttonAttr = prepareButtonAttrs(
    link?.link,
    link?.title,
    link?.targetBlank
  );
  if (modal) {
    return (
      <Modal {...modal}>
        <SiteBlock
          topGap={topGap}
          bottomGap={bottomGap}
          sideGap={sideGap}
          className={cnFormSuccess({ view })}
        >
          <Typography.Title
            size={titleSize}
            className={cnFormSuccess('Title')}
            as="h2"
          >
            {title}
          </Typography.Title>
          <Typography.Text
            className={cnFormSuccess('Text')}
            size={textSize}
            parse
          >
            {text}
          </Typography.Text>
          {Object.keys(buttonAttr).length > 0 && (
            <Button
              className={cnFormSuccess('Submit')}
              view="secondary"
              iconRight={IconDownload}
              {...buttonAttr}
            />
          )}
        </SiteBlock>
      </Modal>
    );
  }

  return (
    <SiteBlock
      topGap={topGap}
      bottomGap={bottomGap}
      sideGap={sideGap}
      className={cnFormSuccess({ view })}
    >
      <Typography.Title
        size={titleSize}
        className={cnFormSuccess('Title')}
        as="h2"
      >
        {title}
      </Typography.Title>
      <Typography.Text className={cnFormSuccess('Text')} size={textSize} parse>
        {text}
      </Typography.Text>
      {Object.keys(buttonAttr).length > 0 && (
        <Button
          className={cnFormSuccess('Submit')}
          view="secondary"
          iconRight={IconDownload}
          {...buttonAttr}
        />
      )}
    </SiteBlock>
  );
};
