import './HotVacancies.scss';

import React from 'react';

import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';

import { cn } from 'ui/utils/bem';

import { Typography } from '@dataartdev/uikit/Typography';
import { IconProps } from '@dataartdev/uikit/Icon';
import { Slider, SliderItem, renderFraction } from '@dataartdev/uikit/Slider';
import { Button } from '@dataartdev/uikit/Button';
import { VacancyCard } from '../VacancyCard/VacancyCard';

import { IconFire } from '@dataartdev/uikit/IconFire';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

type Props = {
  title?: string;
  buttonLabel?: string;
  items: any;
  iconTitle?: React.FC<IconProps>;
};

const cnHotVacancies = cn('HotVacancies');

export const HotVacancies: React.FC<Props> = (
  { title, iconTitle = IconFire, items, buttonLabel },
  ref
) => {
  const { teamSiteMostWantedBlock } = useSelector(dictionariesSelector) || {};

  const IconTitle = iconTitle;
  const sliderOptions = {
    watchOverflow: true,
    slidesPerView: 1,
    slidesPerColumn: 4,
    spaceBetween: 100,
    navigation: {
      nextEl: '#hotVacanciesNext',
      prevEl: '#hotVacanciesPrev',
    },
    pagination: {
      el: '#hotVacanciesPagination',
      clickable: true,
      type: 'fraction',
      renderFraction: renderFraction,
    },
  };

  return (
    <div className={cnHotVacancies()}>
      <div className={cnHotVacancies('Header')}>
        <Typography.Text
          transform="uppercase"
          size="xs"
          weight="bold"
          className={cnHotVacancies('Title')}
          as="h2"
        >
          <IconTitle />
          {title ?? teamSiteMostWantedBlock?.teamSiteMostWantedBlockTitle}
        </Typography.Text>

        <div className="swiper-pagination" id="hotVacanciesPagination" />
      </div>

      <div className={cnHotVacancies('Slider')}>
        <Slider {...sliderOptions}>
          {items.map((item, index) => (
            <SliderItem key={index}>
              <VacancyCard {...item} />
            </SliderItem>
          ))}
        </Slider>
      </div>
      <div className={cnHotVacancies('Bottom')}>
        {(buttonLabel ??
          teamSiteMostWantedBlock?.teamSiteMostWantedBlockMoreText) && (
          <div className={cnHotVacancies('Button')}>
            <Button
              view="link-secondary"
              iconRight={IconArrowRight}
              iconSize={'m'}
              label={
                buttonLabel ??
                teamSiteMostWantedBlock?.teamSiteMostWantedBlockMoreText
              }
            />
          </div>
        )}

        <div className={cnHotVacancies('Nav')}>
          <div className="swiper-button-prev" id="hotVacanciesPrev"></div>
          <div className="swiper-button-next" id="hotVacanciesNext"></div>
        </div>
      </div>
    </div>
  );
};
