import { Location } from 'history';
import queryString from 'query-string';
import { camelizeKeys } from 'humps';
import { QueryFilter } from '../../common/hooks';

export const getUrlFilter = (location: Location<any>): QueryFilter => {
  const {
    page = 1,
    mode,
    industriesIds,
    contentTypes,
    technologiesIds,
    tagsIds,
    languagesCode,
    isOnline,
    startDate,
    endDate,
    isArchived,
    name,
  } = camelizeKeys(queryString.parse(location.search)) as QueryFilter;

  return {
    page: Number(page),
    industriesIds,
    contentTypes,
    technologiesIds,
    languagesCode,
    tagsIds,
    isOnline,
    startDate,
    endDate,
    isArchived,
    mode,
    name,
  };
};
