import { PackageRoutesConfig, RouteConfig } from 'router/models';

export const generateRoutes = (
  packagesRoutes: PackageRoutesConfig[]
): Array<RouteConfig> =>
  packagesRoutes.reduce((acc: Array<RouteConfig>, routes) => {
    return [
      ...acc,
      ...routes.routes.map(route => ({
        path: route.path,
        component: route.component || null,
        layout: route.layout || routes.layout,
        exact: !!route.exact,
        redirect: route.redirect || null,
        update: route.update || 'none',
      })),
    ];
  }, []);
