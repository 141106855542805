import React from 'react';
import './ErrorMessage.scss';

type Props = {
  error: string;
};

const ErrorMessage: React.FC<Props> = ({ error }) => {
  return <div className="error-message">{error}</div>;
};

export default ErrorMessage;
