import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
// import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from 'common/utils';
import { DataQueryVariables } from '../../../common/hooks';

export const queryLocationsPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/contact-us`), {
    params: {
      lang: locale,
    },
  });
};
