import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ILayoutState, IScreenState } from './models';

//LAYOUT
export const layoutSelector = (state: RootState) => state.layout;

export const layoutKeySelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.key
);

export const dictionariesSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.dictionaries?.companySite
);

export const menuSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.menu
);

export const languagesSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.languages
);

export const headerInvertedSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.headerInverted
);

export const headerTransparentSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.headerTransparent
);

export const metaTitlePageSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.metaTitlePage
);

export const modalDataSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.modalData
);

export const modalSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.modal
);

export const formModalSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.formModal
);

export const contactButtonVisibleSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.contactButtonVisible
);

export const searchRequestSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.searchRequest
);

export const searchModalVisibleSelector = createSelector(
  layoutSelector,
  (state: ILayoutState) => state.searchModalVisible
);

//SCREEN
export const screenSelector = (state: RootState) => state.screen;
export const isMobileSelector = createSelector(
  screenSelector,
  (state: IScreenState) => state.isMobile
);
export const isTabletSelector = createSelector(
  screenSelector,
  (state: IScreenState) => state.isTablet
);
export const isDesktopSelector = createSelector(
  screenSelector,
  (state: IScreenState) => state.isDesktop
);
export const isDesktopXLSelector = createSelector(
  screenSelector,
  (state: IScreenState) => state.isDesktopXL
);
export const screenWidthSelector = createSelector(
  screenSelector,
  (state: IScreenState) => state.width
);
