/* eslint-disable react/react-in-jsx-scope */
// @ts-nocheck
import React from 'react';
import { generatePath } from 'react-router-dom';

import { BlogCard } from '../components/BlogCard/BlogCard';
import { CaseCard } from '../components/CaseCard/CaseCard';
import { DefaultCard } from '../components/DefaultCard/DefaultCard';
import { LinkCard } from '../components/LinkCard/LinkCard';
import { VideoCard } from '../components/VideoCard/VideoCard';

export const renderReference = (props, aliases, locale) => {
  const { contentType, item } = props;

  switch (contentType) {
    // Blog post
    case 0:
      return (
        <BlogCard
          {...item}
          path={generatePath(aliases.BLOG_SINGLE, {
            slug: item.slug,
            lang: locale === 'en' ? null : locale,
          })}
          {...props}
          detailLinkTitle={null}
        />
      );

    // CaseCard
    case 1:
      return (
        <CaseCard
          {...item}
          {...props}
          routePath={generatePath(aliases.CASE_PAGE, {
            slug: item.slug,
            lang: locale === 'en' ? null : locale,
          })}
        />
      );

    // Landing
    case 2:
      return (
        <DefaultCard
          view="filled"
          {...item}
          redirectTo={item.link}
          detailLinkTitle={null}
        />
      );

    // VideoCard
    case 3:
      return <VideoCard view="filled" {...item} detailLinkTitle={null} />;

    // Offering
    case 4:
      if (item.industrySlug) {
        return (
          <DefaultCard
            view="filled"
            {...item}
            path={generatePath(aliases.INDUSTRIES_PAGE_INNER, {
              slug: item.industrySlug,
              inner_page: item.slug,
              lang: locale === 'en' ? null : locale,
            })}
            linkTitle={props.linkTitle}
          />
        );
      }
      break;

    // LinkCard
    case 6:
      return <LinkCard {...item} {...props} />;

    default:
      break;
  }
};
