import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';
import { clearUrl } from '../../../common/utils';

export const queryEventPage: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  const { slug, locale } = variables || {};
  return httpClient.get(clearUrl(`/event/${slug}`), {
    params: {
      lang: locale,
    },
  });
};
