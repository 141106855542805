import './Footer.scss';

import React from 'react';

import { useSelector } from 'react-redux';
import { layoutSelector, languagesSelector, dictionariesSelector } from 'store';

import { SocialButton } from '@dataartdev/uikit/SocialButton';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';

import { Button } from '@dataartdev/uikit/Button';
import { Article } from '../../components/Article/Article';
import { FooterMenuColumn } from '../../components/FooterMenuColumn/FooterMenuColumn';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cn } from 'ui/utils/bem';
import { Combobox } from '@dataartdev/uikit/Combobox';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import LinkedInTag from 'react-linkedin-insight';
import { BadgeCarousel } from '../../components/BadgeCarousel/BadgeCarousel';

type Props = RouteComponentProps & {};

const cnFooter = cn('Footer');

LinkedInTag.init('1066788', 'dc', false);

export const Footer: React.FC<Props> = ({ match }) => {
  const { footer } = useSelector(layoutSelector);
  const language = window.INITIAL_STATE.languages.find(
    w => w.slug === localStorage.getItem('currentLang')
  );

  const { companySiteFooter } = useSelector(dictionariesSelector) || {};

  const { companySiteFooterSeeAll: seeAll } = companySiteFooter || {};

  const availableTranslations: string[] = useSelector(languagesSelector) || [];

  const languages = window.INITIAL_STATE.languages.filter(w =>
    availableTranslations.includes(w.iso.toLowerCase())
  );

  const {
    socialLinks,
    columnITitleLink,
    columnILinks,
    columnIITitleLink,
    columnIILinks,
    pagesLinks,
    columnIVTitleLink,
    columnIVLinks,
    locations,
    locationsLink,
    copyright,
    badges,
  } = footer || {};

  return (
    <div className={cnFooter()}>
      <div className="container">
        {socialLinks && socialLinks.length > 0 && (
          <Grid
            breakpoints={{
              desktop: { cols: 12 },
              tablet: { cols: 12 },
              mobile: { cols: 12 },
            }}
          >
            <GridItem
              breakpoints={{
                desktop: { colStart: 2, col: 11 },
                tablet: { col: 12 },
                mobile: { col: 12 },
              }}
              className={cnFooter('Socials')}
            >
              {socialLinks.map(social => (
                <SocialButton
                  key={social.id}
                  icon={() => <Picture {...social.icon} />}
                  as="a"
                  href={social.link}
                />
              ))}
            </GridItem>
          </Grid>
        )}

        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 12 },
            mobile: { cols: 12 },
          }}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 11, colStart: 2 },
              tablet: { col: 12 },
              mobile: { col: 12 },
            }}
            className={cnFooter('Menu')}
          >
            <div className={cnFooter('Nav')}>
              <FooterMenuColumn
                titleLink={columnITitleLink}
                links={columnILinks}
              />
              <div className={cnFooter('Nav-Column')}>
                {pagesLinks && pagesLinks.length > 0 && (
                  <div className={cnFooter('Nav-List')}>
                    {pagesLinks.map(item => (
                      <div className={cnFooter('Nav-Primary')} key={item.id}>
                        <Typography.Text
                          as={!item.targetBlank ? 'link' : 'a'}
                          size="xs"
                          weight="bold"
                          transform="uppercase"
                          to={!item?.targetBlank && item.link}
                          href={item?.targetBlank && item.link}
                          key={item.id}
                        >
                          {item.title}
                        </Typography.Text>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <FooterMenuColumn
                titleLink={columnIITitleLink}
                links={columnIILinks}
                seeAll={seeAll || 'see all'}
              />
              <FooterMenuColumn
                titleLink={columnIVTitleLink}
                links={columnIVLinks}
              />
            </div>
          </GridItem>
        </Grid>

        <Grid
          breakpoints={{
            desktop: { cols: 12, colGap: 'xl' },
            tablet: { cols: 12, colGap: 'xl' },
            mobile: { cols: 12 },
          }}
          className={cnFooter('Bottom')}
        >
          <GridItem
            breakpoints={{
              desktop: { col: 8, colStart: 2 },
              tablet: { col: 8 },
              mobile: { col: 12 },
            }}
          >
            <div className={cnFooter('Locations')}>
              {locations && locations.length > 0 && (
                <Grid
                  breakpoints={{
                    desktop: { cols: 12, colGap: 'xl', rowGap: '4xl' },
                    tablet: { cols: 12, colGap: 'xl', rowGap: '4xl' },
                    mobile: { cols: 12, colGap: 'xl', rowGap: 'xl' },
                  }}
                >
                  {locations.map(item => (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 3 },
                        tablet: { col: 6 },
                        mobile: { col: 6 },
                      }}
                      key={item.id}
                    >
                      <Typography.Title
                        size="tiny"
                        className={cnFooter('Locations-Name')}
                      >
                        {item.title}
                      </Typography.Title>
                      <div>
                        <Typography.Text
                          size="s"
                          as="a"
                          href={'tel:' + item.phone}
                        >
                          {item.phone}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text
                          size="s"
                          as="a"
                          href={'mailto:' + item.email}
                        >
                          {item.email}
                        </Typography.Text>
                      </div>
                    </GridItem>
                  ))}
                  {locationsLink && (
                    <GridItem
                      breakpoints={{
                        desktop: { col: 12 },
                        tablet: { col: 12 },
                        mobile: { col: 12 },
                      }}
                    >
                      <Button
                        view="link-secondary"
                        isLink={!locationsLink.targetBlank}
                        label={locationsLink.title}
                        to={
                          !!locationsLink.targetBlank ? '' : locationsLink.link
                        }
                        href={
                          !locationsLink.targetBlank ? locationsLink.link : ''
                        }
                        iconRight={IconArrowRight}
                      />
                    </GridItem>
                  )}
                </Grid>
              )}
            </div>
            <div className={cnFooter('Info')}>
              <Grid
                breakpoints={{
                  desktop: { cols: 12, gap: 'xl' },
                  tablet: { cols: 12, gap: 'xl' },
                  mobile: { cols: 12, gap: 'xl' },
                }}
              >
                <GridItem
                  breakpoints={{
                    desktop: { col: 1 },
                    tablet: { col: 2 },
                    mobile: { col: 12 },
                  }}
                >
                  {languages.length > 1 && (
                    <div className={cnFooter('Langs')}>
                      <Combobox
                        view="transparent"
                        dropdownView="transparent"
                        items={languages.filter(w => w.iso !== language.iso)}
                        getItemLabel={item => item.title}
                        value={language}
                        onChange={({ value }) => {
                          localStorage.setItem('currentLang', value.slug);
                          if (!value.slug) {
                            window.location.href = generatePath(
                              `/${match.path.substring(18)}`,
                              {
                                ...match.params,
                                lang: value.slug,
                              }
                            );
                          }
                          window.location.href = generatePath(match.path, {
                            ...match.params,
                            lang: value.slug,
                          });
                        }}
                      />
                    </div>
                  )}
                </GridItem>
                <GridItem
                  breakpoints={{
                    desktop: { col: 11 },
                    tablet: { col: 10 },
                    mobile: { col: 12 },
                  }}
                >
                  {copyright && <Article content={copyright} size="s" />}
                </GridItem>
              </Grid>
            </div>
          </GridItem>
          {badges && badges.length > 0 && (
            <GridItem
              breakpoints={{
                desktop: { col: 3 },
                tablet: { col: 4 },
                mobile: { col: 12 },
              }}
              className={cnFooter('Badges')}
            >
              <BadgeCarousel badges={badges} />
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  );
};
