import { InitialResponse, Message } from './models';
import axios from 'axios';

const BACKEND_API = process.env.REACT_APP_BACKEND_FORM_API;

export const initializeChat = async (): Promise<InitialResponse> => {
  const response = await axios.get(`${BACKEND_API}/assistant`);
  return response.data;
};

export const sendMessage = async (
  dialogId: string,
  question: string
): Promise<Message> => {
  const response = await axios.post(`${BACKEND_API}/assistant/submit`, {
    dialogId,
    question,
  });
  return response.data;
};
