import './ProjectsCardsSlider.scss';

import React from 'react';

import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { ProjectCard } from '../ProjectCard/ProjectCard';
import { cn } from '../../utils/bem';

type Props = {
  items: any;
};

const projectsSliderOptions = {
  watchOverflow: true,
  navigation: true,
  spaceBetween: 8,
  breakpoints: {
    767: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
  },
};

const cnProjectsCardsSlider = cn('ProjectsCardsSlider');

export const ProjectsCardsSlider: React.FC<Props> = ({ items }, ref) => {
  return (
    <Slider {...projectsSliderOptions} className={cnProjectsCardsSlider()}>
      {items.map(item => (
        <SliderItem key={item.id}>
          <ProjectCard
            {...item}
            picture={item.backgroundImage}
            link={item.link}
            title={item.title}
            text={item.text}
          />
        </SliderItem>
      ))}
    </Slider>
  );
};
