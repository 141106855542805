import './MainForm.scss';

import React, { HTMLAttributes, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { TextField } from '@dataartdev/uikit/TextField';
import { Pagination } from '@dataartdev/uikit/Pagination';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Mq } from '@dataartdev/uikit/Mq';
import { Modal } from '@dataartdev/uikit/Modal';
import parse from 'html-react-parser';
import { cnGetInTouchForm } from 'common/components/GetInTouchForm/GetInTouchForm';
import { mutationMainForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { cn } from 'ui/utils/bem';
import { schemaLetsTalk } from 'common/utils';
import { dictionariesSelector, layoutSelector } from 'store';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { usePagePath } from '../../hooks/usePagePath';
import { useFormik } from 'formik';
import sbjs from 'sourcebuster';
sbjs.init();

interface Props extends HTMLAttributes<HTMLDivElement> {}

const cnMainForm = cn('MainForm');
const stepsTotal = 6;

export const MainForm: React.FC<Props> = () => {
  const { companySiteForm } = useSelector(dictionariesSelector) || {};
  const { pageId } = useSelector(layoutSelector) || {};
  const [currentStep, setCurrentStep] = useState(1);
  const [successForm, setSuccessForm] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const {
    companySiteFormLetsTalkTrackingID,
    companySiteFormLetsTalkTitle,
    companySiteFormLetsTalkText,
    companySiteFormLetsTalkName,
    companySiteFormLetsTalkCompany,
    companySiteFormLetsTalkAddInfo,
    companySiteFormLetsTalkSubscribePolicy,
    companySiteFormLetsTalkSuccessTitle,
    companySiteFormLetsTalkSuccessText,
    companySiteFormLetsTalkEmail: {
      companySiteFormLetsTalkEmailError = '',
    } = {},
    companySiteFormLetsTalkRequiredField,
    companySiteFormLetsTalkCompanyErrors: {
      companySiteFormLetsTalkCompanyMaxCharactersError = '',
      companySiteFormLetsTalkCompanyMinCharactersError = '',
    } = {},
  } = companySiteForm?.companySiteFormLetsTalk || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID = companySiteFormLetsTalkTrackingID || '';

  const initialValues = {
    name: '',
    phone: '',
    company: '',
    email: '',
    additionalInfo: '',
    privacyAgreement: false,
  };

  const {
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: schemaLetsTalk(
      companySiteFormLetsTalkEmailError,
      companySiteFormLetsTalkRequiredField,
      companySiteFormLetsTalkCompanyMaxCharactersError,
      companySiteFormLetsTalkCompanyMinCharactersError
    ),
    onSubmit: async (values, actions) => {
      if (currentStep < 6) {
        setCurrentStep(currentStep + 1);
        await actions.validateForm();
      } else {
        const token = await reCaptchaRef.current?.executeAsync();

        await mutationMainForm({
          body: {
            ...values,
            pageId: pageId,
            [`${recaptchaFormKey}`]: token,
            cameFrom: cameFrom,
            trackingId: submitButtonID,
          },
        }).then(() => {
          setSuccessForm(true);
          setCurrentStep(1);
          actions.resetForm();

          TagManager.dataLayer({
            dataLayer: {
              event: 'send_form',
              form_name: 'letstalk',
              page_path: pagePath,
              label: values.email,
              tags: 'not set',
            },
          });
        });
      }
    },
  });

  return (
    <div className={cnMainForm()} id="main-form-01">
      <div className="container">
        <Grid
          breakpoints={{
            desktop: { cols: 12, gap: 'xl', yAlign: 'bottom' },
            tablet: { gap: '4xl' },
          }}
        >
          <GridItem breakpoints={{ desktop: { col: 6, colStart: 2 } }}>
            {companySiteFormLetsTalkTitle && (
              <Typography.Title
                size="lg"
                className={cnMainForm('Title')}
                as="span"
              >
                {companySiteFormLetsTalkTitle}
              </Typography.Title>
            )}
            {companySiteFormLetsTalkText && (
              <Mq query="--mobile">
                <Typography.Text size="s" className={cnMainForm('SubTitle')}>
                  {parse(companySiteFormLetsTalkText)}
                </Typography.Text>
              </Mq>
            )}
            <form className={cnMainForm('Inner')} onSubmit={handleSubmit}>
              <div className={cnMainForm('Body')}>
                {currentStep === 1 && (
                  <TextField
                    name="name"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.name}
                    label={companySiteFormLetsTalkName}
                    view="outline"
                    width="full"
                    caption={
                      touched.name && errors.name ? errors.name : undefined
                    }
                    status={touched.name && !!errors.name ? 'alert' : undefined}
                  />
                )}
                {currentStep === 2 && (
                  <TextField
                    name="phone"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.phone}
                    label={'Phone'}
                    view="outline"
                    width="full"
                    type="tel"
                    pattern="^(?=[^A-Za-zЀ-ӿ]+$).*[0-9].*$"
                    caption={
                      touched.phone && errors.phone ? errors.phone : undefined
                    }
                    status={
                      touched.phone && !!errors.phone ? 'alert' : undefined
                    }
                  />
                )}
                {currentStep === 3 && (
                  <TextField
                    name="email"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.email}
                    label={'Email'}
                    view="outline"
                    width="full"
                    required
                    caption={
                      touched.email && errors.email ? errors.email : undefined
                    }
                    status={
                      touched.email && !!errors.email ? 'alert' : undefined
                    }
                  />
                )}
                {currentStep === 4 && (
                  <TextField
                    name="company"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.company}
                    label={companySiteFormLetsTalkCompany}
                    view="outline"
                    width="full"
                    type="tel"
                    caption={
                      touched.company && errors.company
                        ? errors.company
                        : undefined
                    }
                    status={
                      touched.company && !!errors.company ? 'alert' : undefined
                    }
                  />
                )}
                {currentStep === 5 && (
                  <TextField
                    name="additionalInfo"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.additionalInfo}
                    label={companySiteFormLetsTalkAddInfo}
                    view="outline"
                    width="full"
                    caption={
                      touched.additionalInfo && errors.additionalInfo
                        ? errors.additionalInfo
                        : undefined
                    }
                    status={
                      touched.additionalInfo && !!errors.additionalInfo
                        ? 'alert'
                        : undefined
                    }
                  />
                )}
                {currentStep === 6 && (
                  <Checkbox
                    required
                    label={companySiteFormLetsTalkSubscribePolicy}
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    name="privacyAgreement"
                    checked={values.privacyAgreement}
                  />
                )}
                <Pagination
                  className={cnMainForm('Pagination')}
                  currentPage={currentStep}
                  totalPages={stepsTotal}
                  size="xs"
                  hideControls
                />
              </div>
              <div className={cnMainForm('Button')}>
                <Button
                  id={
                    currentStep >= 6
                      ? companySiteFormLetsTalkTrackingID
                      : undefined
                  }
                  iconRight={IconArrowRight}
                  disabled={
                    isSubmitting || !dirty || !!Object.keys(errors).length
                  }
                  type="submit"
                />
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={recaptchaKey}
                  size="invisible"
                />
              </div>
            </form>
          </GridItem>
          {companySiteFormLetsTalkText && (
            <Mq query="--tablet-min">
              <GridItem
                breakpoints={{ desktop: { col: 5 } }}
                className={cnMainForm('Right')}
              >
                <Typography.Text size="s">
                  {parse(companySiteFormLetsTalkText)}
                </Typography.Text>
              </GridItem>
            </Mq>
          )}
        </Grid>
      </div>

      <Modal
        view="secondary"
        hasOverlay
        onOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onClose={() => {
          document.body.style.overflow = 'unset';
        }}
        isOpen={successForm}
        onOverlayClick={(): void => {
          setSuccessForm(false);
          document.body.style.overflow = 'unset';
        }}
        onEsc={(): void => {
          setSuccessForm(false);
          document.body.style.overflow = 'unset';
        }}
        showCloseButton
        inverted
      >
        <div className={cnGetInTouchForm('ModalSuccess')}>
          {companySiteFormLetsTalkSuccessTitle && (
            <Typography.Title
              size="lg"
              className={cnGetInTouchForm('Title')}
              as="h2"
            >
              {companySiteFormLetsTalkSuccessTitle}
            </Typography.Title>
          )}
          {companySiteFormLetsTalkSuccessText && (
            <Typography.Text className={cnGetInTouchForm('SubTitle')}>
              {companySiteFormLetsTalkSuccessText}
            </Typography.Text>
          )}
        </div>
      </Modal>
    </div>
  );
};
