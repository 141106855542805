import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { IIndustriesPage } from '../../../features/industries/pages/models';

export const queryIndustriesPage: DataQueryFunction<IIndustriesPage> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl('/industries/'), {
    params: {
      lang: locale,
    },
  });
};
