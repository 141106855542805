import './ResourceForm.scss';

import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { Typography } from '@dataartdev/uikit/Typography';
import { Picture } from '@dataartdev/uikit/Picture';
import { useSelector } from 'react-redux';
import { layoutSelector } from 'store';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { mutationResourceForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Article } from '../../Article/Article';
import { cnModalPage } from '../ModalPage';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { usePagePath } from '../../../hooks/usePagePath';
import { Message } from 'yup/lib/types';
import { useFormik } from 'formik';
import { IResourceForm, TModalData } from '../models';
import sbjs from 'sourcebuster';

sbjs.init();

interface Props {
  modalData: TModalData;
}

function isResourceForm(data: TModalData): data is IResourceForm {
  return 'formType' in data;
}

export const ResourceForm: React.FC<Props> = ({ modalData }) => {
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const { pageId } = useSelector(layoutSelector) || {};
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const { dictionary, file } = modalData as IResourceForm;
  const {
    companySiteFormDownloadFileName = '',
    companySiteFormDownloadFileSuccessTitle = '',
    companySiteFormDownloadFileSubmit = '',
    companySiteFormDownloadFileSuccessText = '',
    companySiteFormDownloadFileTrackingID = '',
    companySiteFormDownloadFileSubscribePolicy = '',
  } = dictionary?.companySiteFormDownloadFile || {};

  const pagePath = usePagePath();

  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;
  const submitButtonID =
    companySiteFormDownloadFileTrackingID || 'file_download';

  const schema = (
    emailError: Message = 'Invalid email address',
    requiredError: Message = 'Field is required'
  ) => [
    yup.object({
      email: yup
        .string()
        .email(emailError)
        .required(requiredError)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      company: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
    yup.object({
      email: yup
        .string()
        .email(emailError)
        .required(requiredError)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
    yup.object({
      email: yup
        .string()
        .email(emailError)
        .required(requiredError)
        .nullable(),
      name: yup
        .string()
        .min(3)
        .nullable(),
      company: yup
        .string()
        .min(3)
        .nullable(),
      privacyAgreement: yup
        .boolean()
        .oneOf([true], 'on')
        .required(),
    }),
  ];

  const initValues = {
    email: '',
    name: '',
    company: '',
    privacyAgreement: false,
  };

  const {
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
  } = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: async (values, actions) => {
      try {
        const token = await reCaptchaRef.current?.executeAsync();

        await mutationResourceForm({
          body: {
            ...values,
            pageId,
            [`${recaptchaFormKey}`]: token,
            cameFrom: cameFrom,
            trackingId: submitButtonID,
          },
        });
        window.open(file.url, '_blank');
        TagManager.dataLayer({
          dataLayer: {
            event: 'send_form',
            form_name: submitButtonID,
            page_path: pagePath,
            label: values.email,
            tags: 'not set',
          },
        });
        actions.resetForm();
        setSuccessForm(true);
      } catch (error) {
        error instanceof Error
          ? console.error(error.message)
          : console.error(error);
      }
    },
  });

  const largeForm = isResourceForm(modalData) && modalData.formType === 4;
  const leftSideCols = largeForm ? 5 : 12;
  const rightSideCols = largeForm ? 7 : 12;

  return (
    <div className={cnModalPage({ resource: true, largeForm: largeForm })}>
      {modalData.title && (
        <Typography.Text
          size="xs"
          weight="bold"
          transform="uppercase"
          as="h3"
          className={cnModalPage('Title')}
        >
          {modalData.title}
        </Typography.Text>
      )}
      <Grid
        breakpoints={{
          desktop: { cols: 12, gap: largeForm ? '6xl' : 'xl' },
          tablet: { cols: 1, gap: 'xl' },
          mobile: { cols: 1, gap: 'xl' },
        }}
      >
        <GridItem breakpoints={{ desktop: { col: leftSideCols } }}>
          {isResourceForm(modalData) && modalData.subtitle && (
            <Typography.Title
              as="h2"
              size="md"
              className={cnModalPage('Title')}
            >
              {modalData.subtitle}
            </Typography.Title>
          )}
          {modalData.text && (
            <Article className={cnModalPage('Text')} content={modalData.text} />
          )}
          {modalData.picture && largeForm && (
            <Picture
              className={cnModalPage('PicturePreview')}
              {...modalData.picture}
            />
          )}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: rightSideCols } }}>
          <div className={cnModalPage('Body')}>
            <form onSubmit={handleSubmit}>
              {isResourceForm(modalData) &&
                (modalData.formType === 2 || largeForm) && (
                  <TextField
                    name="name"
                    onChange={({ e }) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.name}
                    label={companySiteFormDownloadFileName}
                    view="outline"
                    width="full"
                    caption={
                      touched.name && errors.name ? errors.name : undefined
                    }
                    status={touched.name && !!errors.name ? 'alert' : undefined}
                  />
                )}
              <TextField
                name="email"
                onChange={({ e }) => handleChange(e)}
                onBlur={handleBlur}
                value={values.email}
                label="E-mail"
                view="outline"
                width="full"
                required
                caption={
                  touched.email && errors.email ? errors.email : undefined
                }
                status={touched.email && !!errors.email ? 'alert' : undefined}
              />
              <div className={cnModalPage('Bottom')}>
                <Checkbox
                  required
                  label={companySiteFormDownloadFileSubscribePolicy}
                  onChange={({ e }) => handleChange(e)}
                  name="privacyAgreement"
                  checked={values.privacyAgreement}
                />
                <div className={cnModalPage('Button')}>
                  <Button
                    id={companySiteFormDownloadFileTrackingID}
                    iconRight={IconArrowRight}
                    disabled={
                      isSubmitting || !dirty || !!Object.keys(errors).length
                    }
                    label={companySiteFormDownloadFileSubmit}
                    type="submit"
                  />
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={recaptchaKey}
                    size="invisible"
                  />
                </div>
              </div>
            </form>
          </div>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          {companySiteFormDownloadFileSuccessTitle && (
            <Typography.Title size="md" className={cnModalPage('Title')}>
              {companySiteFormDownloadFileSuccessTitle}
            </Typography.Title>
          )}
          {companySiteFormDownloadFileSuccessText && (
            <Typography.Text className={cnModalPage('SubTitle')}>
              {companySiteFormDownloadFileSuccessText}
            </Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};
