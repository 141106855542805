import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationClutchForm: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body } = variables;

  return httpClient.post('/clutch/submit', body);
};
