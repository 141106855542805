import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from '../../../common/utils';
import { IWhyDataart } from '@features/company/pages/models/why_dataart';

export const queryWhyDataart: DataQueryFunction<IWhyDataart> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/why-dataart`), {
    params: {
      lang: locale,
    },
  });
};
