import React, { useLayoutEffect } from 'react';
import { history } from 'services/history/history';
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'store';
import { UserProvider } from './UserProvider';
import { Theme, presetDefault } from '@dataartdev/uikit/Theme';

import ScrollToTop from 'ui/utils/scrollToTop';
import { TLanguages, LocalizedRouter } from 'i18n';

const App: React.FC = () => {
  // clear initial history state
  useLayoutEffect(() => {
    if (localStorage.getItem('currentLang') === 'en') {
      localStorage.setItem('currentLang', '');
    }

    if (history.location.state) {
      history.replace({ ...history.location, state: undefined });
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <LocalizedRouter
        defaultLanguage={localStorage.getItem('currentLang') as TLanguages}
      >
        <ScrollToTop />
        <Theme preset={presetDefault}>
          <UserProvider history={history} />
        </Theme>
      </LocalizedRouter>
    </StoreProvider>
  );
};

export default App;
