import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { INewsRoomPage } from '@features/company/pages/models/news_room';

export const queryNewsRoomPage: DataQueryFunction<INewsRoomPage> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/newsroom`), {
    params: {
      lang: locale,
    },
  });
};
