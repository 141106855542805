import React from 'react';
import './TypingIndicator.scss';

const TypingIndicator: React.FC = () => {
  return (
    <div className="da-chatbot-typing-indicator da-chatbot-message da-chatbot-message--bot">
      <span className="da-chatbot-typing-dot" />
      <span className="da-chatbot-typing-dot" />
      <span className="da-chatbot-typing-dot" />
    </div>
  );
};

export default TypingIndicator;
