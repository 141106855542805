import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { DataQueryVariables } from 'common/hooks';

export const queryInitialData: DataQueryFunction = (
  variables: DataQueryVariables | undefined
): Promise<any> => {
  return httpClient.get(`/pages/content`, {
    params: {
      ...variables,
    },
  });
};
