import { History } from 'history';
import queryString from 'query-string';
import { camelizeKeys } from 'humps';
import { clearFalsyProps } from 'common/utils';

export const updateQueryParams = (history: History, params: any): void => {
  const { pathname, search } = history.location;

  const currentQuery = queryString.parse(search);

  const newParams = { ...params };

  if (newParams.page) {
    newParams.page = newParams.page === 1 ? 0 : params.page;
  }

  const newQueryString = queryString.stringify(
    clearFalsyProps({
      ...camelizeKeys(currentQuery),
      ...newParams,
    })
  );

  history.replace({ pathname, search: newQueryString });
};
