import { languageRegex } from '../i18n';

export interface RoutePaths {
  MAIN: string;
  BLOG: string;
  BLOG_SINGLE: string;
  FAQ: string;
  TEAM: string;
  TEAM_ADVISOR: string;
  TEAM_MEMBER: string;
  COMPANY: string;
  ABOUT_US: string;
  WHY_DATAART: string;
  LOCATIONS: string;
  LOCATIONS_PRESS_KIT: string;
  LOCATIONS_SINGLE: string;
  CLIENTS: string;
  CLIENT_PAGE: string;
  CASE_PAGE: string;
  EVENTS: string;
  EVENTS_ARCHIVE: string;
  EVENTS_PAGE: string;
  EVENTS_ARCHIVE_PAGE: string;
  LIFE_PAGE: string;
  INTERNSHIP_PAGE: string;
  SUSTAINABILITY_PAGE: string;
  AWARDS_PAGE: string;
  ANONYMOUS_PAGE: string;
  SERVICES: string;
  SERVICES_PAGE: string;
  SERVICES_PAGE_INNER: string;
  SERVICES_PAGE_INNER_SECONDARY: string;
  INDUSTRIES: string;
  INDUSTRIES_PAGE: string;
  INDUSTRIES_PAGE_INNER: string;
  SEARCH_PAGE: string;
  NEWSROOM: string;
  NEWSROOM_PAGE: string;
  PLAIN_TEXT: string;
}

export const PATH: RoutePaths = {
  MAIN: `/:lang(${languageRegex})?`,
  BLOG: `/:lang(${languageRegex})?/blog`,
  BLOG_SINGLE: `/:lang(${languageRegex})?/blog/:slug`,
  FAQ: `/:lang(${languageRegex})?/faq`,
  TEAM: `/:lang(${languageRegex})?/company/team/`,
  TEAM_ADVISOR: `/:lang(${languageRegex})?/company/team/advisor/:slug`,
  TEAM_MEMBER: `/:lang(${languageRegex})?/company/team/:slug`,
  COMPANY: `/:lang(${languageRegex})?/company`,
  ABOUT_US: `/:lang(${languageRegex})?/company/about-us`,
  WHY_DATAART: `/:lang(${languageRegex})?/company/why-dataart`,
  LOCATIONS: `/:lang(${languageRegex})?/contact-us`,
  LOCATIONS_PRESS_KIT: `/:lang(${languageRegex})?/contact-us/press-kit`,
  LOCATIONS_SINGLE: `/:lang(${languageRegex})?/contact-us/:slug`,

  CLIENTS: `/:lang(${languageRegex})?/clients`,
  CLIENT_PAGE: `/:lang(${languageRegex})?/clients/:slug`,
  CASE_PAGE: `/:lang(${languageRegex})?/clients/case-studies/:slug`,
  EVENTS: `/:lang(${languageRegex})?/company/events/`,
  EVENTS_ARCHIVE: `/:lang(${languageRegex})?/company/events/archive`,
  EVENTS_PAGE: `/:lang(${languageRegex})?/company/events/:slug`,
  EVENTS_ARCHIVE_PAGE: `/:lang(${languageRegex})?/company/events/archive/:slug`,
  LIFE_PAGE: `/:lang(${languageRegex})?/life`,
  INTERNSHIP_PAGE: `/:lang(${languageRegex})?/internship`,
  SUSTAINABILITY_PAGE: `/:lang(${languageRegex})?/company/sustainability/`,
  AWARDS_PAGE: `/:lang(${languageRegex})?/company/awards/`,

  ANONYMOUS_PAGE: `/:lang(${languageRegex})?/anonymous-report`,

  SERVICES: `/:lang(${languageRegex})?/services`,
  SERVICES_PAGE: `/:lang(${languageRegex})?/services/:slug`,
  SERVICES_PAGE_INNER: `/:lang(${languageRegex})?/services/:slug/:inner_page`,
  SERVICES_PAGE_INNER_SECONDARY: `/:lang(${languageRegex})?/services/:slug/:inner_page/:inner_page_secondary`,

  INDUSTRIES: `/:lang(${languageRegex})?/industries`,
  INDUSTRIES_PAGE: `/:lang(${languageRegex})?/industries/:slug/`,
  INDUSTRIES_PAGE_INNER: `/:lang(${languageRegex})?/industries/:slug/:inner_page`,

  SEARCH_PAGE: `/:lang(${languageRegex})?/search-page`,

  NEWSROOM: `/:lang(${languageRegex})?/company/newsroom`,
  NEWSROOM_PAGE: `/:lang(${languageRegex})?/company/newsroom/:parentSlug/:slug`,
  PLAIN_TEXT: `/:lang(${languageRegex})?/:slug?/:slug_2?/:slug_3?`,
};
