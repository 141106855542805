import './EventCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { IconPlay } from '@dataartdev/uikit/IconPlay';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import { TagGroup } from '@dataartdev/uikit/TagGroup';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';
import { IPicture } from '@commonTypes/picture';
import { IEvent } from '@commonTypes/event';
import { nanoid } from '@reduxjs/toolkit';
import { ICard } from '../ContentBuilder/templates/models/CTI_28.props';
import { CardPropSize, CardPropView } from '../EssenceCard/models';

interface Props
  extends Omit<IEvent, 'type' | 'contentType'>,
    Omit<ICard, 'id' | 'type'> {
  routePath?: string;
  view?: CardPropView;
  size?: CardPropSize;
  picture?: IPicture;
  invertedStyle?: any;
  redirectTo?: string;
  onlineTitle?: string;
  type?: IEvent['type'] | ICard['type'];
}

export const cnEventCard = cn('EventCard');

export const EventCard: React.FC<Props> = props => {
  const {
    routePath,
    view,
    picture,
    date,
    tags,
    isOnline,
    languages,
    title,
    size = 'm',
    invertedStyle,
    locations,
    speakers = [],
    registrationStatus,
    redirectTo,
    eventType,
    fullUrl,
    eventTypeText,
    location,
    eventWithoutPage,
    onlineTitle,
    video,
    cardDictionary,
  } = usePropsHandler(cnEventCard(), props);

  const { eventCard } = cardDictionary || {};

  let redirectToPage = redirectTo;

  if (
    Number(process.env.REACT_APP_EVENT_TYPE) !== Number(eventType) &&
    !redirectToPage &&
    fullUrl
  ) {
    redirectToPage = fullUrl;
  }

  return (
    <div
      className={cnEventCard({
        view,
        size,
        invertedStyle,
        noHover: eventWithoutPage,
      })}
    >
      {!eventWithoutPage && (
        <>
          {redirectToPage && (
            <a
              className={cnEventCard('Link')}
              href={redirectToPage}
              target="_blank"
              rel="noreferrer"
              aria-label={title}
            />
          )}
          {!redirectToPage && routePath && (
            <NavLink
              className={cnEventCard('Link')}
              to={routePath}
              aria-label={title}
            />
          )}
        </>
      )}

      {picture && (
        <div className={cnEventCard('Picture')}>
          <Picture {...picture} />
        </div>
      )}
      <div className={cnEventCard('Inner')}>
        {(date || locations || location) && isOnline && (
          <div className={cnEventCard('Date')}>
            {date && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                {date}
              </Typography.Text>
            )}
            {locations && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |{' '}
                {isOnline
                  ? eventCard?.companySiteEventCardOnline ?? onlineTitle
                  : locations.map(
                      location => `${location.country}, ${location.location}`
                    )}
              </Typography.Text>
            )}
            {location && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |{' '}
                {isOnline
                  ? eventCard?.companySiteEventCardOnline ?? onlineTitle
                  : `${location.country}, ${location.location}`}
              </Typography.Text>
            )}
            {!location && !locations && isOnline && (
              <Typography.Text size="xs" weight="bold" transform="uppercase">
                |&nbsp;
                {isOnline
                  ? eventCard?.companySiteEventCardOnline ?? onlineTitle
                  : null}
              </Typography.Text>
            )}
          </div>
        )}
        <div className={cnEventCard('Title')}>
          <Typography.Title size="tiny" weight="bold" as="h3">
            {title}
          </Typography.Title>
        </div>
        {eventTypeText && (
          <div className={cnEventCard('SubTitle')}>
            <Typography.Text size="s">{eventTypeText}</Typography.Text>
          </div>
        )}
        <div className={cnEventCard('Tags')}>
          {tags && <TagGroup items={tags} />}
        </div>
        <div className={cnEventCard('Speakers')}>
          {video && (
            <div
              className={cnEventCard('VideoCircle')}
              style={{ zIndex: speakers?.length > 0 ? speakers.length + 1 : 1 }}
            >
              <Button
                view="primary"
                form="circle"
                iconRight={IconPlay}
                size="4xl"
                iconSize="s"
                onlyIcon
                aria-label="play button"
              />
            </div>
          )}
          {speakers &&
            speakers.map((speaker, index) => (
              <React.Fragment key={nanoid()}>
                {speaker.photo && (
                  <User
                    size="m"
                    avatarUrl={speaker.photo?.urlWebp || speaker.photo?.url}
                    style={{ zIndex: speakers.length - index }}
                  />
                )}
              </React.Fragment>
            ))}
        </div>
        {languages && (
          <div className={cnEventCard('Bottom')}>
            <TagGroup items={languages} mode="label" gap="xs" />
          </div>
        )}
        {registrationStatus && (
          <div className={cnEventCard('Bottom')}>
            <Tag
              mode="label"
              label={registrationStatus.title ?? ''}
              colorBorder={registrationStatus.color}
              gap="xs"
            />
          </div>
        )}
      </div>
    </div>
  );
};
