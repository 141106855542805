import React from 'react';
import { cn } from '../../utils/bem';
import './BadgeCarousel.scss';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { Typography } from '@dataartdev/uikit/Typography';
import { Badge } from '../../../store/models';

const cnBadgeCarousel = cn('BadgeCarousel');

const badgeCarouselConfig = {
  watchOverflow: false,
  navigation: false,
  pagination: {
    clickable: true,
  },
  slidesPerView: 1,
  spaceBetween: 0,
};

interface Props {
  badges: Badge[];
}

export const BadgeCarousel: React.FC<Props> = ({ badges }) => {
  return (
    <div className={cnBadgeCarousel()}>
      <Slider {...badgeCarouselConfig} className={cnBadgeCarousel('Carousel')}>
        {badges.map(badge => {
          const { icon, link, title, targetBlank, id } = badge || {};
          return (
            <SliderItem key={id}>
              <div className={cnBadgeCarousel('Slide')}>
                <a href={link} target={targetBlank ? '_blank' : '_self'}>
                  {icon && (
                    <img
                      src={icon.urlWebp || icon.url}
                      srcSet={icon.urlWebp || icon.url}
                      alt={icon.alt}
                      className={cnBadgeCarousel('Img')}
                    />
                  )}
                  {title && (
                    <Typography.Text
                      parse
                      size="m"
                      as="span"
                      className={cnBadgeCarousel('Caption')}
                    >
                      {title}
                    </Typography.Text>
                  )}
                </a>
              </div>
            </SliderItem>
          );
        })}
      </Slider>
    </div>
  );
};
