/* eslint-disable no-param-reassign */
// @ts-nocheck

import './Article.scss';
import './old-content.scss';

import React from 'react';
import parse, { domToReact } from 'html-react-parser';

import { cn } from '../../utils/bem';
import { renderReference } from '../../utils/renderReference';
import { SiteBlock } from '../SiteBlock/SiteBlock';
import { Tooltip } from '@dataartdev/uikit/Tooltip';

import {
  renderContentBanner,
  renderContentClients,
  renderContentListLinks,
  renderContentMembers,
  renderContentPartners,
  renderContentPosts,
  renderContentPostsSecondary,
  renderContentSlider,
  renderContentVacancy,
  renderScrollButton,
} from './helpers/contents';

type Props = {
  content?: string;
  size?: string;
  className?: string;
  references?: any;
};

export const cnArticle = cn('Article');

export const Article = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    content,
    className,
    size,
    contentPosts,
    team,
    links,
    posts,
    landing,
    clients,
    partners,
    vacancy,
    references,
    slider,
    chatTooltips,
    scrollerBtn,
  } = props;

  const contentBody = () => {
    return parse(content, {
      replace: domNode => {
        if (
          domNode.children &&
          domNode.children[0] &&
          domNode.children[0].name &&
          domNode.children[0].name === 'iframe' &&
          domNode.children[0].attribs.class &&
          !domNode.children[0].attribs.class.includes('da-quiz')
        ) {
          return (
            <div className="iframe-container">
              {domToReact(domNode.children, domNode.options)}
            </div>
          );
        }

        if (
          domNode.attribs?.id &&
          references &&
          references.items &&
          references.path
        ) {
          const code = domNode.attribs?.id;
          const item = references.items.find(w => w.textCode === code);

          if (item) {
            const Tag = domNode.name as string;

            const Item = renderReference(
              item,
              references.path,
              references.lang
            );

            return (
              <div className="Article-SideItemWrapper">
                <Tag {...domNode.attribs}>
                  {domToReact(domNode.children, domNode.options)}
                </Tag>
                {Item && (
                  // <Mq query="--tablet-max">
                  <div className="Article-SideItemInner">
                    <SiteBlock
                      useMargin
                      topGap="xxl"
                      bottomGap="xxl"
                      className="Article-SideItem"
                    >
                      {Item}
                    </SiteBlock>
                  </div>
                  // </Mq>
                )}
              </div>
            );
          }
        }

        if (domNode.attribs?.id && chatTooltips) {
          const code = domNode.attribs?.id;
          const item = chatTooltips.find(w => w.code === code);

          if (item) {
            const Tag = domNode.name as string;

            return (
              <div className="Article-SideItemWrapper">
                <Tag {...domNode.attribs}>
                  {domToReact(domNode.children, domNode.options)}
                </Tag>
                <div className="Article-SideItemInner">
                  <SiteBlock
                    useMargin
                    topGap="m"
                    bottomGap="2xl"
                    className="Article-SideItem"
                  >
                    <Tooltip staticPosition directionPosition="upStartLeft">
                      {item.text}
                    </Tooltip>
                  </SiteBlock>
                </div>
              </div>
            );
          }
        }

        if (domNode.data) {
          if (domNode.data.includes(contentPosts?.code)) {
            // domNode.parent?.name = 'div';
            return renderContentPosts(contentPosts);
          }

          if (domNode.data.includes(slider?.code)) {
            domNode.parent.name = 'div';
            return renderContentSlider(slider);
          }

          if (domNode.data.includes(team?.code)) {
            domNode.parent.name = 'div';
            return renderContentMembers(team);
          }

          if (domNode.data.includes(links?.code)) {
            domNode.parent.name = 'div';
            return renderContentListLinks(links);
          }

          if (domNode.data.includes(clients?.code)) {
            domNode.parent.name = 'div';
            return renderContentClients(clients);
          }

          if (domNode.data.includes(posts?.code)) {
            domNode.parent.name = 'div';
            return renderContentPostsSecondary(posts);
          }

          if (domNode.data.includes(landing?.code)) {
            domNode.parent.name = 'div';
            return renderContentBanner(landing);
          }

          if (domNode.data.includes(partners?.code)) {
            domNode.parent.name = 'div';
            return renderContentPartners(partners);
          }

          if (domNode.data.includes(vacancy?.code)) {
            domNode.parent.name = 'div';
            if (!vacancy.items?.length) {
              domNode.data = domNode.data.replace(vacancy.code, '');
            }
            return renderContentVacancy(vacancy);
          }

          if (domNode.data.includes(scrollerBtn?.code)) {
            domNode.parent.name = 'div';
            return renderScrollButton(scrollerBtn);
          }
        }
      },
    });
  };

  return (
    <>
      {content && (
        <article className={cnArticle({ size }, [className])} ref={ref}>
          {contentBody()}
        </article>
      )}
    </>
  );
});
