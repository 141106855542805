// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './LinkCard.scss';

import React from 'react';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';

import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';

export const cardPropSize = ['s', 'm', 'l'] as const;
export type CardPropSize = typeof cardPropSize[number];
export const cardPropSizeDefault: CardPropSize = cardPropSize[1];

export const cardPropView = ['default'] as const;

export type CardPropView = typeof cardPropView[number];
export const cardPropViewDefault: CardPropView = cardPropView[0];

export type Props = {
  size?: CardPropSize;
  view?: CardPropView;
};

export const cnLinkCard = cn('LinkCard');

export const LinkCard: React.FC<Props> = props => {
  const {
    view = cardPropViewDefault,
    size = cardPropSizeDefault,
    title,
    linkText,
    link,
    targetBlank,
  } = usePropsHandler(cnLinkCard(), props);

  return (
    <div
      className={cnLinkCard({
        view,
        size,
      })}
    >
      <Typography.Title size="md" className={cnLinkCard('Title')} as="h3">
        {title}
      </Typography.Title>
      <div className={cnLinkCard('Button')}>
        <Button
          iconRight={IconArrowRight}
          label={linkText}
          as="a"
          target={targetBlank ? '_blank' : null}
          href={link}
        />
      </div>
    </div>
  );
};
