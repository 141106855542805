/* eslint-disable react/style-prop-object */
// @ts-nocheck
import './HeaderLogo.scss';

import React from 'react';

import { cn } from '../../../utils/bem';

export const cnHeaderLogo = cn('HeaderLogo');

export type HeaderProps<
  As extends keyof JSX.IntrinsicElements
> = PropsWithAsAttributes<
  {
    children: never;
  },
  As
>;

export function HeaderLogo<As extends keyof JSX.IntrinsicElements = 'div'>(
  props: HeaderProps<As>
): React.ReactElement {
  const { as = 'div', className, ...otherProps } = props;
  const Tag = as as string;

  return (
    <Tag className={cnHeaderLogo(null, [className])} {...otherProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 162.2 26"
        xmlSpace="preserve"
        width="123"
      >
        <path
          d="m13.6 12.7 5.8 5.8 5-5 .7-.8-5.7-5.8zM6.3 20 .9 25.4h11.5l5.4-5.4zm1.3-1.3h10.2l-5.1-5.1zm6.6 6.7h11.2V14.2zM0 24.5l11.8-11.8L0 .9zM20.3 6l5.1 5.2V.9zm-7.6 5.8L24.5 0H.9zM43.7 0h-8.1v25.4h7.8c5.2 0 12.8-3.2 12.8-12.6S48.1 0 43.7 0zm-.4 20.5h-1.9V4.9h2.1c1.8 0 6.6 1.3 6.6 7.8s-5.2 7.8-6.8 7.8zM71.6 8.8v2.3l-.6-.7c-1.2-1.4-3-2.1-4.8-2.1-2.2 0-4.3.8-5.9 2.3a9.19 9.19 0 0 0-2.5 6.7c-.1 2.3.8 4.5 2.3 6.1C61.6 25 63.7 26 66 26c1.9.1 3.8-.8 5-2.3l.7-1v2.8h4.8V8.8h-4.9zM67 21.5c-2.4.1-4.4-1.9-4.5-4.3-.1-2.4 1.9-4.4 4.3-4.5 2.4-.1 4.4 1.9 4.5 4.3v.1c0 2.4-1.9 4.4-4.3 4.4zm38.3-12.7v2.3l-.7-.8c-1.2-1.4-3-2.1-4.8-2.1-2.2 0-4.3.8-5.9 2.3a9.19 9.19 0 0 0-2.5 6.7c-.1 2.3.8 4.5 2.3 6.1 1.5 1.7 3.6 2.6 5.8 2.6 1.9.1 3.8-.8 5-2.3l.7-1v2.8h4.8V8.8h-4.7zm-4.7 12.7c-2.4.1-4.4-1.9-4.5-4.3-.1-2.4 1.9-4.4 4.3-4.5 2.4-.1 4.4 1.9 4.5 4.3v.1c0 2.4-1.9 4.4-4.3 4.4zM81.5 0v8.8h-1.9v4.3h1.9v12.3h5.4V13.1h3.4V8.8h-3.4V0zm71.9 0v25.4h5.3V13.1h3.5V8.8h-3.5V0zm-24.6 16.8h-7.3L125.2 6l3.6 10.8zm3.1 8.6h6L128.5 0h-6.6l-9.6 25.4h6l1.8-4.4h10.2l1.6 4.4zm7.9 0h5.3v-7c-.1-1.6.4-3.1 1.5-4.3.8-.7 1.9-1.1 3.1-1h2.2V8.8c-.2 0-1.1-.1-1.1-.1-.9-.1-1.9.1-2.7.4-1 .5-1.7 1.3-2.2 2.2l-.7 1.4v-4h-5.3l-.1 16.7z"
          fill="#fff"
        />
      </svg>
      <svg
        width="124"
        height="20"
        viewBox="0 0 124 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.0147 15.8109H31.5935V3.98404H33.2209C34.6287 3.98404 38.296 4.94322 38.296 9.94433C38.296 14.9454 34.2655 15.8154 33.0192 15.8154L33.0147 15.8109ZM33.342 0.258856H27.173V19.5406H33.1537C37.1483 19.5406 42.9317 17.0869 42.9317 9.93987C42.9317 2.79287 36.6775 0.254395 33.3375 0.254395"
          fill="black"
        />
        <path
          d="M51.1361 16.5738C49.3293 16.5738 47.8633 15.0837 47.8633 13.2456C47.8633 11.4076 49.3293 9.91751 51.1361 9.91751C52.9428 9.91751 54.4088 11.4076 54.4088 13.2456C54.4088 15.0837 52.9428 16.5738 51.1361 16.5738ZM54.6689 6.96413V8.72634L54.1398 8.13299C53.6018 7.53071 52.4138 6.52246 50.4681 6.52246C48.7599 6.52246 47.0922 7.18273 45.9982 8.2936C45.2809 9.02079 44.0794 10.6492 44.0794 13.3661C44.0794 15.2354 44.6308 16.663 45.8593 18.0014C47.0966 19.3264 48.5985 20 50.3201 20C51.2706 20 53.0235 19.7725 54.1174 18.2378L54.6644 17.4705V19.5584H58.3093V6.95967H54.6689V6.96413Z"
          fill="black"
        />
        <path
          d="M76.7803 16.5738C74.9735 16.5738 73.5075 15.0837 73.5075 13.2456C73.5075 11.4076 74.9735 9.91751 76.7803 9.91751C78.587 9.91751 80.0531 11.4076 80.0531 13.2456C80.0531 15.0837 78.587 16.5738 76.7803 16.5738ZM80.3131 6.96413V8.72634L79.7841 8.13299C79.2461 7.53071 78.058 6.52246 76.1123 6.52246C74.4042 6.52246 72.7364 7.18273 71.6425 8.2936C70.9251 9.02079 69.7236 10.6492 69.7236 13.3661C69.7236 15.2354 70.2751 16.663 71.5035 18.0014C72.7409 19.3264 74.2428 20 75.9643 20C76.9148 20 78.6677 19.7725 79.7616 18.2378L80.3086 17.4705V19.5584H83.9535V6.95967H80.3131V6.96413Z"
          fill="black"
        />
        <path
          d="M62.2232 0.259033V6.96436H60.7392V10.2211H62.2232V19.5541H66.2715V10.2211H68.9211V6.96436H66.2715V0.259033H62.2232Z"
          fill="black"
        />
        <path
          d="M117.031 0.254395V19.554H121.079V10.2209H123.729V6.96419H121.079V0.254395H117.031Z"
          fill="black"
        />
        <path
          d="M95.5024 4.796L98.3089 13.0048H92.7676L95.5024 4.796ZM93.0321 0.254395L85.7423 19.5406H90.3018L91.6378 16.1634H99.3938L100.613 19.5406H105.222L98.0713 0.254395H93.0321Z"
          fill="black"
        />
        <path
          d="M106.693 19.5539H110.741V14.2138C110.741 13.6784 110.741 12.0411 111.884 10.9793C112.458 10.4484 113.22 10.1897 114.215 10.1897C114.901 10.1897 115.65 10.2075 115.919 10.212V6.97308C115.731 6.9597 115.112 6.92847 115.112 6.92847C114.328 6.92847 113.588 6.92847 113.023 7.24076C112.297 7.61105 111.705 8.19994 111.31 8.94943L110.736 10.038V6.96416H106.688V19.5584L106.693 19.5539Z"
          fill="black"
        />
        <path
          d="M14.537 5.07601L9.68988 9.89941L14.537 14.7228L19.3842 9.89941L14.537 5.07601Z"
          fill="#FFDE55"
        />
        <path
          d="M19.3811 9.89972L14.5347 5.07706L19.3811 0.254395V9.89972Z"
          fill="#2BC6BF"
        />
        <path
          d="M4.84637 14.7179L9.69278 9.89966L14.5347 14.7179H4.84637Z"
          fill="#F0503C"
        />
        <path
          d="M9.69281 19.5405L19.3811 9.89966V19.5405H9.69281Z"
          fill="#53CFF8"
        />
        <path
          d="M9.69281 19.5407L14.5347 14.718H4.8464L0 19.5407H9.69281Z"
          fill="#70529F"
        />
        <path
          d="M0 0.254395L9.69281 9.89972L19.3811 0.254395H0Z"
          fill="#284086"
        />
        <path
          d="M0 19.5406L9.69281 9.89972L0 0.254395V19.5406Z"
          fill="#FFB133"
        />
      </svg>
    </Tag>
  );
}
