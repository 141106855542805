/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import './ProjectCard.scss';

import React, { useMemo } from 'react';

import { cn } from '../../utils/bem';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { TypographyPropSize } from '@dataartdev/uikit/__internal__/cjs-src/components/Typography/Title/Title';

export const projectCardPropView = ['default'] as const;
export type ProjectCardPropView = typeof projectCardPropView[number];
export const projectCardPropViewDefault: ProjectCardPropView =
  projectCardPropView[0];

type Props = {
  view?: ProjectCardPropView;
  title?: string;
  titleSize?: TypographyPropSize;
  text?: string;
  link?: any;
  picture?: any;
};

const cnProjectCard = cn('ProjectCard');

export const ProjectCard: React.FC<Props> = props => {
  const {
    view = projectCardPropViewDefault,
    title,
    link,
    picture,
    additionalLinks,
  } = props;

  const LinkImageComponent = useMemo(
    () => <>{link?.icon && <img src={link?.icon?.url} alt="photo" />}</>,
    [link]
  );

  return (
    <div className={cnProjectCard({ view })}>
      <a
        className={cnProjectCard('OverlayLink')}
        target={link.targetBlank ? '_blank' : null}
        href={link.link}
        rel={link.targetBlank ? 'noreferrer noopener' : null}
      />
      {picture && (
        <div className={cnProjectCard('Picture')}>
          <Picture desktop={picture.url} desktopWebp={picture.urlWebp} />
        </div>
      )}
      <div className={cnProjectCard('Inner')}>
        <Typography.Text className={cnProjectCard('Title')}>
          {title}
        </Typography.Text>
        {/* {text && (
          <div
            className={cnProjectCard('Text')}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )} */}

        {additionalLinks?.length > 0 && (
          <div className={cnProjectCard('Links')}>
            {additionalLinks.map(additionalLink => (
              <div className={cnProjectCard('Links-Item')}>
                <Button
                  label={additionalLink.title}
                  as="a"
                  target={additionalLink.targetBlank ? '_blank' : null}
                  href={additionalLink.link}
                  view="link-secondary"
                  badge={{
                    text: additionalLink.labelText,
                    inverted: additionalLink.labelInverted,
                    color: additionalLink.labelColor,
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {link && (
          <div className={cnProjectCard('Button')}>
            <Button
              label={link?.title}
              view="link-secondary"
              icon={!!link?.icon && LinkImageComponent}
              iconRight={!link?.icon && IconOpenInNew}
              iconSize="xs"
              target={link.targetBlank ? '_blank' : null}
              as="a"
              href={link.link}
            />
          </div>
        )}
      </div>
    </div>
  );
};
