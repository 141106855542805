import React from 'react';
import { IntlProvider } from 'react-intl';
import {
  Route,
  Redirect,
  BrowserRouter,
  useParams,
  useLocation,
} from 'react-router-dom';
import { httpClient } from 'network';
import { languageRegex, TLanguages } from '../languages';

interface Props {
  children: React.ReactNode;
  defaultLanguage: TLanguages;
}

const LanguageHandler: React.FC<Props> = ({ children, defaultLanguage }) => {
  const { lang: urlLang } = useParams<{ lang?: string }>();
  const location = useLocation();

  const initialLang = window.INITIAL_STATE.detectedLanguage.slug;
  const initialISO = window.INITIAL_STATE.detectedLanguage.iso;

  const lang = urlLang || defaultLanguage || initialLang;
  const findCurrentLang =
    window.INITIAL_STATE.languages.find(w => w.slug === lang) ||
    window.INITIAL_STATE.detectedLanguage;
  localStorage.setItem('currentLang', lang);
  httpClient.defaults.headers.common['Accept-Language'] =
    findCurrentLang.iso || initialISO;

  const shouldRedirect = !location.pathname.includes(`/${lang}`);
  const newPath = `/${lang}${location.pathname}`;

  if (shouldRedirect) {
    return <Redirect to={newPath} />;
  }

  return (
    <IntlProvider locale={lang !== '' ? lang : 'en'}>{children}</IntlProvider>
  );
};

export const LocalizedRouter: React.FC<Props> = ({
  children,
  defaultLanguage,
}) => (
  <BrowserRouter>
    <Route
      path={`/:lang(${languageRegex})?`}
      component={() => (
        <LanguageHandler
          defaultLanguage={defaultLanguage}
          children={children}
        />
      )}
    />
  </BrowserRouter>
);
