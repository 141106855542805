import { clearFalsyProps } from 'common/utils/clearFalsyProps';
import { DataQueryVariables } from 'common/hooks';

export const toPaginationParams = (
  variables: DataQueryVariables | undefined
) => {
  const page =
    (variables && variables.pagination && variables.pagination.page) || 0;
  const pageSize =
    (variables && variables.pagination && variables.pagination.pageSize) || 0;

  return clearFalsyProps({ page: page > 1 ? page : 1, pageSize });
};
