import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { IMainPage } from '../../../features/main/pages/models';

export const queryHomePage: DataQueryFunction<IMainPage> = variables => {
  const { locale } = variables || {};

  return httpClient.get(clearUrl(`/home`), {
    params: {
      lang: locale,
    },
  });
};
