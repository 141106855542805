import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { IServicesPageNew } from '@features/services/pages/models';

export const queryServicesPage: DataQueryFunction<IServicesPageNew> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl('/services'), {
    params: {
      lang: locale,
    },
  });
};
