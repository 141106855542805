import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import qs from 'qs';
import { toPaginationParams } from 'network/helpers';
import { clearUrl, clearFalsyProps } from 'common/utils';
import { IInsight } from '../../../features/blog/pages/blog_page_models';

export const queryBlogPage: DataQueryFunction<IInsight> = variables => {
  const { locale } = variables || {};
  const { industriesIds, tagsIds, contentTypes, mode, name } =
    (variables && variables.filter) || {};
  const paginationParams = toPaginationParams(variables);

  const filterParams = clearFalsyProps({
    industriesIds,
    tagsIds,
    contentTypes,
    mode,
    name,
  });

  return httpClient.get(clearUrl(`/insights`), {
    params: {
      ...filterParams,
      ...paginationParams,
      lang: locale,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};
