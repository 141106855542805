// @ts-nocheck

import * as yup from 'yup';
import { Message } from 'yup/lib/types';

yup.addMethod(yup.string, 'integer', function() {
  return this.matches(/^\d+$/, 'The field should have digits only');
});

export const schemaLetsTalk = (
  emailError: Message<{ regex: RegExp }> | undefined,
  requiredError: Message<{}> | undefined,
  maxCharactersError: Message<{}> | undefined,
  minCharactersError: Message<{}> | undefined
) => [
  yup.object({
    name: yup
      .string()
      .min(3)
      .max(80)
      .nullable(),
  }),
  yup.object({
    phone: yup
      .string()
      .integer()
      .min(6)
      .max(20)
      .nullable(),
  }),
  yup.object({
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
  }),
  yup.object({
    company: yup
      .string()
      .min(3, minCharactersError)
      .max(254, maxCharactersError)
      .nullable(),
  }),
  yup.object({
    additionalInfo: yup
      .string()
      .min(3)
      .nullable(),
  }),
  yup.object({
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  }),
];

export const schemaLetsTalkAll = (
  emailError: Message<{ regex: RegExp }> | undefined = 'Invalid email address',
  requiredError: Message<{}> | undefined = 'Field is required',
  maxCharactersError: Message<{}> | undefined = 'Field is too long',
  minCharactersError: Message<{}> | undefined = 'Field is to short'
) =>
  yup.object({
    name: yup
      .string()
      .min(3)
      .max(80)
      .nullable(),
    phone: yup
      .string()
      .integer()
      .min(6)
      .max(20)
      .nullable(),
    company: yup
      .string()
      .min(3, minCharactersError)
      .max(255, maxCharactersError)
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    additionalInfo: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });

export const schemaLetsTalkAllPhoneRequired = (
  emailError: Message<{ regex: RegExp }> | undefined,
  requiredError: Message<{}> | undefined
) =>
  yup.object({
    name: yup.string().nullable(),
    phone: yup
      .string()
      .integer()
      .min(6)
      .max(20)
      .nullable(),
    company: yup
      .string()
      .min(3)
      .nullable(),
    email: yup
      .string()
      .email(emailError)
      .required(requiredError)
      .nullable(),
    additionalInfo: yup
      .string()
      .min(3)
      .nullable(),
    privacyAgreement: yup
      .boolean()
      .oneOf([true], 'on')
      .required(),
  });
