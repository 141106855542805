import { ThunkDispatch } from 'redux-thunk';
import { queryInitialData, queryMetaData, queryModal } from 'network';
import { setInitialData, setMetaData, setModal } from './actions';

export const fetchInitialData = (
  params: { [key: string]: any } | null | undefined
) => (dispatch: ThunkDispatch<any, any, any>) =>
  queryInitialData(params).then(res => dispatch(setInitialData(res.data)));

export const fetchMetaData = (
  params: { [key: string]: any } | null | undefined
) => (dispatch: ThunkDispatch<any, any, any>) =>
  queryMetaData(params).then(res => dispatch(setMetaData(res.data)));

export const fetchModal = (
  params: { [key: string]: any } | null | undefined
) => (dispatch: ThunkDispatch<any, any, any>) =>
  queryModal(params).then(res => {
    dispatch(setModal(res.data));
  });
