import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { stringify } from 'query-string';
import { camelizeKeys } from 'humps';
import { ITags } from './models';
import { ISearchPageData } from '../../../features/search/pages/models';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;
declare const window: any;

export const querySearchPage = createApi({
  reducerPath: 'searchPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_API,
  }),
  endpoints: build => ({
    searchPageData: build.query<ISearchPageData, string>({
      query: search => ({
        url: `pages/search/${search}`,
        responseType: 'json',
        paramsSerializer: (params: any) => {
          return stringify(params);
        },
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept-Language': localStorage.getItem('currentLang')
            ? window.INITIAL_STATE.languages.find(
                (w: { slug: string | null }) =>
                  w.slug === localStorage.getItem('currentLang')
              )?.iso
            : window.INITIAL_STATE.detectedLanguage.iso,
        },
      }),
      transformResponse: response => camelizeKeys(response) as ISearchPageData,
    }),
    getAllTags: build.query<ITags[], void>({
      query: () => {
        return {
          url: 'pages/search/tags',
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
    }),
    getCurrentTagData: build.query<ISearchPageData, string>({
      query: search => {
        const searchParam = search.substring(search.indexOf('=') + 1);
        return {
          url: `pages/search`,
          params: {
            tags: searchParam,
          },
          responseType: 'json',
          paramsSerializer: (params: any) => {
            return stringify(params);
          },
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
      transformResponse: response => camelizeKeys(response) as ISearchPageData,
    }),
    getNameData: build.query<ISearchPageData, string>({
      query: search => {
        const searchParam = search.substring(search.indexOf('=') + 1);
        return {
          url: `pages/search?name=${searchParam.trim().replaceAll(' ', '%20')}`,
          responseType: 'json',
          paramsSerializer: (params: any) => {
            return stringify(params);
          },
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': localStorage.getItem('currentLang')
              ? window.INITIAL_STATE.languages.find(
                  (w: { slug: string | null }) =>
                    w.slug === localStorage.getItem('currentLang')
                )?.iso
              : window.INITIAL_STATE.detectedLanguage.iso,
          },
        };
      },
      transformResponse: response => camelizeKeys(response) as ISearchPageData,
    }),
  }),
});

export const {
  useLazySearchPageDataQuery,
  useGetAllTagsQuery,
  useLazyGetAllTagsQuery,
  useLazyGetCurrentTagDataQuery,
  useLazyGetNameDataQuery,
} = querySearchPage;
