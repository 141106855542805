/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
// @ts-nocheck
import './VacancyCard.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';

export type Props = {
  view?: 'ghost' | 'block' | null;
  size?: 'lg' | 'md' | 'sm';
  bubleText?: never;
  isHot?: never;
  contentType?: never;
};

export const cnVacancyCard = cn('VacancyCard');

export const VacancyCard = forwardRefWithAs<Props>((props, ref) => {
  const {
    path,
    size,
    title,
    titleSize = 'sm',
    locationTags,
    view,
    text,
    ...otherProps
  } = usePropsHandler(cnVacancyCard(), props);

  return (
    <div
      className={cnVacancyCard({ view, size, link: !!path })}
      ref={ref}
      {...otherProps}
    >
      {path && <NavLink to={path} className={cnVacancyCard('Link')} />}
      <div className={cnVacancyCard('Title')}>
        <Typography.Title size={titleSize} as="h3">
          {title}
        </Typography.Title>
      </div>
      {locationTags?.length > 0 && (
        <ul className={cnVacancyCard('Cities')}>
          {locationTags.map(location => (
            <li key={location.id}>{location.title}</li>
          ))}
        </ul>
      )}
      {text && (
        <Typography.Text className={cnVacancyCard('Text')} size="sm">
          {text}
        </Typography.Text>
      )}
    </div>
  );
});
