import './BlogCard.scss';

import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { forwardRefWithAs } from '../../utils/types/PropsWithAsAttributes';
import { cn } from '../../utils/bem';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Button } from '@dataartdev/uikit/Button';
import { Picture } from '@dataartdev/uikit/Picture';
import { Tag } from '@dataartdev/uikit/Tag';
import * as Typography from '@dataartdev/uikit/Typography';
import { ICard } from '../ContentBuilder/templates/models/CTI_28.props';
import { TypographyPropSize } from '@dataartdev/uikit/__internal__/cjs-src/components/Typography/Title/Title';
import {
  CardPropDirection,
  CardPropSize,
  cardPropSizeDefault,
  cardPropSizeDirection,
  CardPropView,
  cardPropViewDefault,
} from '../EssenceCard/models';
import { ITag } from '../../../types/common';
import { IconProps } from '@dataartdev/uikit/Icon';
import { IconPropSize } from '@dataartdev/uikit/__internal__/src/icons/Icon/Icon';

export interface Props extends ICard {
  size?: CardPropSize;
  view?: CardPropView;
  direction?: CardPropDirection;
  postDate?: string;
  hrvTags?: ITag[];
  linkIcon?: React.FC<IconProps>;
  onClickButton?: () => void;
  descriptionSmall?: string;
  iconSize?: IconPropSize;
  linkTitle?: string;
  detailLinkTitle?: string;
  isExternalTransition?: boolean;
}

const sizeMap: Record<string, TypographyPropSize> = {
  s: 'tiny',
  m: 'sm',
  l: 'md',
};

export const cnBlogCard = cn('BlogCard');

export const BlogCard = forwardRefWithAs<Props>(props => {
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    ...data
  } = usePropsHandler(cnBlogCard(), props);

  const {
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    picture,
    linkIcon = IconArrowRight,
    onClickButton,
    url = '',
    descriptionSmall,
    iconSize = 'm',
    style,
    linkTitle,
    detailLinkTitle,
    isExternalTransition,
    // type,
  } = data || {};

  const tags = useMemo(
    () => (
      <>
        <div className={cnBlogCard('Tags')}>
          {hrvTags && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnBlogCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnBlogCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );

  const onClickButtonHandler = () => {
    if (url && isExternalTransition) {
      window.open(url, '_blank');
    }
  };
  return (
    <div
      className={cnBlogCard({
        view,
        size,
        direction,
      })}
      style={style}
    >
      {url?.includes('http') ? (
        <a className={cnBlogCard('Link')} href={url} target="_blank" />
      ) : (
        <NavLink className={cnBlogCard('Link')} to={url} aria-label={title} />
      )}
      {view !== CardPropView.TEXT &&
        view !== CardPropView.TEXT2 &&
        view !== CardPropView.TEXT3 &&
        picture && (
          <div className={cnBlogCard('Photo')}>
            <Picture {...picture} />
          </div>
        )}

      <div className={cnBlogCard('Body')}>
        {view !== CardPropView.TEXT && view !== CardPropView.TEXT3 && postDate && (
          <div className={cnBlogCard('Date')}>
            <Typography.Typography.Text weight="semibold" size="xs">
              {postDate}
            </Typography.Typography.Text>
          </div>
        )}

        {view !== CardPropView.TEXT && view !== CardPropView.TEXT3 && (
          <div className={cnBlogCard('Date')}>
            <Typography.Typography.Text
              weight="semibold"
              size="xs"
              transform="uppercase"
            >
              read more
            </Typography.Typography.Text>
          </div>
        )}

        {descriptionSmall && (
          <div className={cnBlogCard('DescriptionSmall')}>
            <Typography.Typography.Text
              weight="semibold"
              size="xs"
              transform="uppercase"
            >
              {descriptionSmall}
            </Typography.Typography.Text>
          </div>
        )}

        {view === CardPropView.TEXT3 && tags}

        <div className={cnBlogCard('Title')}>
          <Typography.Typography.Title
            weight="bold"
            size={getSizeByMap(sizeMap, size)}
            as="h3"
          >
            {title}
          </Typography.Typography.Title>
        </div>

        {view !== CardPropView.TEXT &&
          view !== CardPropView.TEXT3 &&
          view !== CardPropView.FEED &&
          tags}

        {view !== CardPropView.TEXT &&
          view !== CardPropView.TEXT2 &&
          view !== CardPropView.FEED &&
          (linkTitle || detailLinkTitle) && (
            <div
              style={{
                pointerEvents: 'none',
              }}
              className={cnBlogCard('Button')}
            >
              <Button
                view="link-secondary"
                iconRight={linkIcon}
                iconSize={iconSize}
                label={detailLinkTitle ?? linkTitle}
                onClick={onClickButton || onClickButtonHandler}
              />
            </div>
          )}
      </div>
    </div>
  );
});
