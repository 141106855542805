import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ITeamPage } from '@features/company/pages/models/team_page';

export const queryTeamPage: DataQueryFunction<ITeamPage> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/team`), {
    params: {
      lang: locale,
    },
  });
};
