import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from '../../../common/utils';
import { ICommonPagesProps } from '@commonTypes/common';

export const queryAboutUsPage: DataQueryFunction<ICommonPagesProps> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/aboutus`), {
    params: {
      lang: locale,
    },
  });
};
