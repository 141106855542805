const createCountFormatter = ({
  one,
  two,
  few,
}: {
  one: string;
  two: string;
  few: string;
}) => {
  const titles = [one, two, few];

  return (number: number): string => {
    const cases = [2, 0, 1, 1, 1, 2];

    return `${number} ${
      titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    }`;
  };
};

export const countFormatDays = createCountFormatter({
  one: 'день',
  two: 'дня',
  few: 'дней',
});

export const countFormatHours = createCountFormatter({
  one: 'час',
  two: 'часа',
  few: 'часов',
});

export const countFormatTasks = createCountFormatter({
  one: 'задача',
  two: 'задачи',
  few: 'задач',
});

export const countFormatRounds = createCountFormatter({
  one: 'раунд',
  two: 'раунда',
  few: 'раундов',
});
