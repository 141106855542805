// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-has-content */
import './ChatBot.scss';
import React, { useState, useRef, useEffect } from 'react';
import { Message as MessageType } from 'network/api/chatBot/models';
import { initializeChat, sendMessage } from 'network/api/chatBot';
import Message from './Message';
import TypingIndicator from './TypingIndicator';
import ErrorMessage from './ErrorMessage';
import { useSelector } from 'react-redux';
import { dictionariesSelector } from 'store';

const CHAT_ID_KEY = 'chat_id';

export const getChatIdFromStorage = (): string | null => {
  return localStorage.getItem(CHAT_ID_KEY);
};

export const setChatIdToStorage = (id: string): void => {
  localStorage.setItem(CHAT_ID_KEY, id);
};

export const removeChatIdFromStorage = (): void => {
  localStorage.removeItem(CHAT_ID_KEY);
};

export const ChatBot: React.FC = () => {
  const [showChatBot, setShowChatBot] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [input, setInput] = useState('');
  const [chatId, setChatId] = useState<string | null>(getChatIdFromStorage());
  const [isChatAvailable, setIsChatAvailable] = useState(false);
  const [botIsTyping, setBotIsTyping] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    companySiteHome: { companySiteHomeChatBotDisclaimer },
  } = useSelector(dictionariesSelector) || {};

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [botIsTyping, messages, showChatBot]);

  const handleSend = async (e: React.FormEven) => {
    e.preventDefault();
    if (input.trim() && chatId) {
      const userInput = input;
      setInput('');
      setBotIsTyping(true);
      setMessages(prev => [
        ...prev,
        { role: 'da-chatbot-message--user', text: userInput },
      ]);

      try {
        const data = await sendMessage(chatId, userInput);
        const { dialog } = data;
        const answer =
          dialog && dialog.length
            ? dialog[dialog.length - 1].content
            : undefined;
        setMessages(prev => [
          ...prev,
          { role: 'da-chatbot-message--bot', text: answer },
        ]);
        setError(null);
      } catch (err) {
        setError('Something went wrong, try sending again');
      } finally {
        setBotIsTyping(false);
      }
    }
  };

  useEffect(() => {
    initializeChat().then((data: Message) => {
      setIsChatAvailable(data.isAIActive && data.isFeatureActive);
    });
  }, []);

  const showChatBotHandler = () => {
    if (!isChatAvailable) {
      return;
    }
    if (showChatBot === true) {
      return;
    }
    setShowChatBot(true);
    if (messages.length > 0) {
      return;
    }
    // if (!!chatId) { TODO: add logic save conversation in localStorage - need specific api
    // INIT CHAT
    initializeChat().then((data: Message) => {
      // setIsChatAvailable(data.isAIActive && data.isFeatureActive);
      setChatId(data.dialogId);
      setChatIdToStorage(data.dialogId);
      setMessages(prev => [
        ...prev,
        { role: 'da-chatbot-message--bot', text: data.dialog[0].content },
      ]);
    });
    // }
  };

  const hideChatBotHandler = () => {
    setShowChatBot(false);
  };

  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const chatContainerRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const isBottom =
  //       Math.round(
  //         chatContainerRef.current.scrollHeight -
  //           chatContainerRef.current.scrollTop
  //       ) === chatContainerRef.current.clientHeight;
  //     setShouldShowScrollButton(!isBottom);
  //   };
  //
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.addEventListener('scroll', handleScroll);
  //   }
  //
  //   return () => {
  //     if (chatContainerRef.current) {
  //       chatContainerRef.current.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [chatContainerRef.current]);

  useEffect(() => {
    let timeoutId = null;

    const handleScroll = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (chatContainerRef.current) {
          const isBottom =
            Math.round(
              chatContainerRef.current.scrollHeight -
                chatContainerRef.current.scrollTop
            ) === chatContainerRef.current.clientHeight;
          setShouldShowScrollButton(!isBottom);
        }
      }, 50); // Задержка в 100 миллисекунд
    };

    const currentChatContainerRef = chatContainerRef.current;

    if (currentChatContainerRef) {
      currentChatContainerRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentChatContainerRef) {
        currentChatContainerRef.removeEventListener('scroll', handleScroll);
      }
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [chatContainerRef.current]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isChatAvailable && (
        <>
          <button className="da-chatbot-handler" onClick={showChatBotHandler} />
          {/* put className da-chatbot-popup--active to show popup / remove to hide it */}
          {showChatBot && (
            <div className="da-chatbot-popup da-chatbot-popup--active">
              <div className="da-chatbot-top">
                <button
                  className="da-chatbot-close"
                  onClick={hideChatBotHandler}
                />
                <span className="da-chatbot-heading">Chat with DataArt</span>
              </div>
              <div className="da-chatbot-inner">
                <div className="da-chatbot-content" ref={chatContainerRef}>
                  {companySiteHomeChatBotDisclaimer && (
                    <div className="da-chatbot-disclaimer">
                      {companySiteHomeChatBotDisclaimer}
                    </div>
                  )}
                  <div className="da-chatbot-messages">
                    {messages.map((message, index) => (
                      <Message key={index} {...message} />
                    ))}
                    {botIsTyping && <TypingIndicator />}
                    {error && <ErrorMessage error={error} />}
                    <div ref={messagesEndRef} />
                  </div>
                </div>
                {/* add or remove class da-chatbot-scroller--visible to show/hide when user scroll */}
                {shouldShowScrollButton && (
                  <button
                    className="da-chatbot-scroller da-chatbot-scroller--visible"
                    onClick={scrollToBottom}
                  />
                )}
                <form className="da-chatbot-ctrls" onSubmit={handleSend}>
                  <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    className="da-chatbot-input"
                    placeholder={`${
                      botIsTyping ? 'Typing...' : 'Ask me anything...'
                    }`}
                    onChange={e => setInput(e.target.value)}
                    disabled={botIsTyping}
                  />
                  <button
                    type="submit"
                    className={`da-chatbot-send ${
                      botIsTyping ? 'disabled' : ''
                    }`}
                    disabled={botIsTyping}
                  />
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
