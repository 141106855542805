import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationLocationsSalesExistingForm: DataMutationFunction = (
  variables = {}
): Promise<any> => {
  const { body } = variables;

  return httpClient.post('/contact-us/sales-existing/submit', body);
};
