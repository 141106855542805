import { DataQueryFunction } from 'network/models';
import { httpClient } from 'network';
import { clearUrl } from 'common/utils';
import { ICommonPagesProps } from '../../../types/common';

export const queryAwards: DataQueryFunction<ICommonPagesProps> = variables => {
  const { locale } = variables || {};
  return httpClient.get(clearUrl(`/pages/awards`), {
    params: {
      lang: locale,
    },
  });
};
