import './TestimonialCard.scss';

import React from 'react';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import { getSizeByMap } from '@dataartdev/uikit/__internal__/cjs-src/utils/getSizeByMap';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { IconProps } from '@dataartdev/uikit/Icon';
import { Typography } from '@dataartdev/uikit/Typography';
import { User } from '@dataartdev/uikit/User';
import { Button } from '@dataartdev/uikit/Button';
import { ITestimonial } from '@commonTypes/common';

type TLink =
  | {
      link?: string;
      title?: string;
      targetBlank?: boolean;
    }
  | string;

export interface Props extends Omit<ITestimonial, 'link' | 'id'> {
  buttonIcon?: IconProps;
  text?: string;
  view?: string;
  size?: string;
  onClickButton?: () => void;
  linkText?: string;
  isFeedback?: boolean;
  link?: TLink;
}

const sizeMapUser = {
  m: undefined,
  s: 'xs',
} as const;

export const cnTestimonialCard = cn('TestimonialCard');

export const TestimonialCard: React.FC<Props> = props => {
  const {
    quote,
    author,
    authorPhoto,
    authorPosition,
    view,
    size = 'm',
    link,
    onClickButton,
    linkText,
    isFeedback,
    flag,
    country,
  } = usePropsHandler(cnTestimonialCard(), props);

  return (
    <div className={cnTestimonialCard({ view, size })}>
      {isFeedback && (
        <>
          {quote && quote.length > 140 && (
            <Typography.Text className={cnTestimonialCard('Title')}>
              {quote}
            </Typography.Text>
          )}
          {quote && quote.length <= 140 && (
            <Typography.Title size="sm" className={cnTestimonialCard('Title')}>
              {quote}
            </Typography.Title>
          )}
        </>
      )}

      {!isFeedback && (
        <Typography.Title size="tiny" className={cnTestimonialCard('Title')}>
          {quote}
        </Typography.Title>
      )}

      <div className={cnTestimonialCard('User')}>
        <User
          name={author}
          info={authorPosition}
          avatarUrl={authorPhoto?.urlWebp || authorPhoto?.url}
          size={getSizeByMap(sizeMapUser, size)}
          flag={flag}
          country={country}
        />
      </div>
      {link && typeof link === 'string' && (
        <Button
          view="link-secondary"
          as="a"
          label={linkText}
          href={link}
          iconRight={IconOpenInNew}
          onClick={onClickButton}
          iconSize="s"
          target={'_self'}
        />
      )}
      {link &&
        typeof link !== 'string' &&
        link.link?.includes('http') &&
        Object.keys(link).length > 0 && (
          <div className={cnTestimonialCard('Button')}>
            <Button
              view="link-secondary"
              as="a"
              label={link.title}
              href={link.link}
              iconRight={IconOpenInNew}
              onClick={onClickButton}
              iconSize="s"
              target={'_self'}
            />
          </div>
        )}
      {link &&
        typeof link !== 'string' &&
        !link.link?.includes('http') &&
        Object.keys(link).length > 0 && (
          <div className={cnTestimonialCard('Button')}>
            <Button
              view="link-secondary"
              iconSize={'m'}
              label={link.title}
              iconRight={IconArrowRight}
              isLink
              to={link.link}
            />
          </div>
        )}
    </div>
  );
};
