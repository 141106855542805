import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

export const usePagePath = (): string => {
  const { locale } = useIntl();
  const location = useLocation();
  const { pathname } = location || {};
  const [localeInPath] = pathname?.split('/').filter(Boolean);
  const pagePath = localeInPath?.includes(locale)
    ? pathname?.substring(3) || '/'
    : pathname;
  return pagePath;
};
