import './MainLayout.scss';

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenWidth } from 'store';

import { MainHeader } from './MainHeader';
import { Footer } from './Footer';
import { ModalPage } from 'common/components';
import { modalDataSelector, modalSelector } from '../../../store';
import { ChatBot } from '../../components/ChatBot/ChatBot';
import { RouteParams } from '../../../router';

type Props = RouteComponentProps<RouteParams> & {
  children: React.ReactNode;
};

export const MainLayout: React.FC<Props> = props => {
  const { children, ...routeProps } = props;
  const dispatch = useDispatch();

  const modalQuery = useSelector(modalSelector);
  const modalPage = useSelector(modalDataSelector);
  const modalData: any = modalQuery || modalPage;

  useEffect(() => {
    const onWindowResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener('resize', onWindowResize);
    onWindowResize();
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [dispatch]);

  const { announcement } = window.INITIAL_STATE;
  const hideBanner =
    announcement && Object.values(announcement).every(value => !!value);
  return (
    <div className={classNames('out')}>
      <MainHeader {...routeProps} />
      <div
        className={`page-inner ${
          !hideBanner ? 'page-inner--banner-hidden' : 'page-inner--banner-show'
        }`}
      >
        {children}
      </div>
      {modalData && <ModalPage />}
      <ChatBot />
      <Footer {...routeProps} />
    </div>
  );
};
