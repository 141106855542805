import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../../router';

import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import { generatePath } from 'react-router-dom';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { useIntl } from 'react-intl';
import { cn } from '../../../ui/utils/bem';
import TagManager from 'react-gtm-module';

const cnFooter = cn('Footer');

type ColumnItitleLink = {
  id: string;
  title: string;
  link: string;
  targetBlank: boolean;
};

type ColumnIlink = {
  id: string;
  title: string;
  link: string;
  targetBlank: boolean;
};
type Props = {
  titleLink: ColumnItitleLink;
  links: ColumnIlink[];
  seeAll?: string;
};

export const FooterMenuColumn: React.FC<Props> = props => {
  const history = useHistory();
  const { titleLink, links, seeAll } = props || {};
  const { locale } = useIntl();
  const lang = locale === 'en' ? null : locale;

  const { link, title } = titleLink || {};
  const typographyLinkAttr = (link: string) => {
    return {
      ...(link.includes('http')
        ? { as: 'a', href: link }
        : { as: 'link', to: link }),
    };
  };
  const linkAttr = typographyLinkAttr(link);
  const servicesMainLink = generatePath(PATH.SERVICES, { lang });

  const servicesHandler = (title: string, link: string) => async (
    e: MouseEvent
  ) => {
    if (!link.includes('service')) return;
    e.preventDefault();
    e.stopPropagation();
    const service = title.trim().toLowerCase();
    let dataLayer = {
      dataLayer: {
        event: 'click_services',
        services:
          service === 'services & technologies'
            ? 'click_all_services'
            : `click_${service.split(' ').join('_')}`,
      },
    };
    try {
      await TagManager.dataLayer(dataLayer);

      if (link.includes('http')) {
        window.location.href = link;
      } else {
        history.push(link);
      }
    } catch (error) {
      console.error('Error with TagManager dataLayer:', error);
    }
  };

  return (
    <div className={cnFooter('Nav-Column')}>
      {titleLink && (
        <Typography.Text
          {...linkAttr}
          size="xs"
          weight="bold"
          transform="uppercase"
          className={cnFooter('Nav-Title')}
          onClick={servicesHandler(title, servicesMainLink)}
        >
          {title}
        </Typography.Text>
      )}
      {links?.length > 0 && (
        <div className={cnFooter('Nav-List')}>
          {links.map(item => {
            const { link, title } = item || {};
            const linkAttr = typographyLinkAttr(link);
            return (
              <div className={cnFooter('Nav-Item')} key={item.id}>
                <Typography.Text
                  size="s"
                  key={item.id}
                  {...linkAttr}
                  onClick={servicesHandler(title, link)}
                >
                  {title}
                </Typography.Text>
              </div>
            );
          })}
          {seeAll && (
            <div className={cnFooter('Nav-See-All')}>
              <Button
                isLink
                view="link-secondary"
                label={seeAll || 'see all'}
                to={servicesMainLink}
                iconRight={IconArrowRight}
                onClick={servicesHandler(
                  'services & technologies',
                  servicesMainLink
                )}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
