/* eslint-disable react/jsx-no-target-blank */
// @ts-nocheck
import React from 'react';
import { generatePath } from 'react-router-dom';

import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Banner } from '../../Banner';
import { EssenceCard } from '../../EssenceCard/EssenceCard';
import { ClientCard } from '../../ClientCard/ClientCard';
import { VacancyCard } from '../../VacancyCard/VacancyCard';
import { MemberCard } from '@dataartdev/uikit/MemberCard';
import { ContentSlider } from '@dataartdev/uikit/ContentSlider';
import { GridItem, Grid } from '@dataartdev/uikit/Grid';
import { ListLinks } from '@dataartdev/uikit/ListLinks';
import { Picture } from '@dataartdev/uikit/Picture';
import { Typography } from '@dataartdev/uikit/Typography';
import { SiteBlock } from '../../SiteBlock/SiteBlock';
import { CtaScrollerBtn } from '../../CtaScrollerBtn';
import { prepareButtonAttrs } from '../../../utils/prepareButtonAttrs';

export const renderContentPosts = ({ items = [], path }) => (
  <Grid
    breakpoints={{
      desktop: { cols: 1, gap: '3xl' },
      tablet: { cols: 2, colGap: 'm', rowGap: '4xl' },
      mobile: { cols: 1, gap: '4xl' },
    }}
    className="Article-ContentPosts"
  >
    {items.map(post => (
      <GridItem col="1" key={post.id + post.title}>
        <EssenceCard {...post} direction="row" path={path(post)} />
      </GridItem>
    ))}
  </Grid>
);

export const renderContentMembers = ({ items, path, title }) => {
  if (items.length && path) {
    return (
      <SiteBlock topGap="xxl" bottomGap="xxl" useMargin>
        {title && (
          <SiteBlock bottomGap="xl" useMargin>
            <Typography.Text size="xs" weight="bold" transform="uppercase">
              {title}
            </Typography.Text>
          </SiteBlock>
        )}
        <Grid
          breakpoints={{
            desktop: { cols: 3, colGap: '2xl', rowGap: 'xl' },
            tablet: { cols: 3, colGap: 'xs', rowGap: 'm' },
            mobile: { cols: 1, gap: 'm' },
          }}
          className="Article-ContentTeam"
        >
          {items.map(item => (
            <GridItem col="1" key={item.id + item.title}>
              <MemberCard {...item} routePath={path(item)} />
            </GridItem>
          ))}
        </Grid>
      </SiteBlock>
    );
  }
};

export const renderContentSlider = ({ items, text }) => {
  if (items.length) {
    return (
      <SiteBlock topGap="3xl" bottomGap="6xl" useMargin>
        <ContentSlider items={items} text={text} />
      </SiteBlock>
    );
  }
};

export const renderContentListLinks = ({ items, title }) => {
  if (items.length) {
    return (
      <SiteBlock topGap="xxl" bottomGap="xxl" useMargin>
        {title && (
          <SiteBlock bottomGap="m" useMargin>
            <Typography.Text size="xs" weight="bold" transform="uppercase">
              {title}
            </Typography.Text>
          </SiteBlock>
        )}
        <ListLinks
          items={items}
          getLabel={item => item.title}
          getExternalLink={item => item.link}
        />
      </SiteBlock>
    );
  }
};

export const renderContentClients = ({ items, path }) => {
  if (items.length && path) {
    return (
      <SiteBlock className="Article-ContentClients" useMargin>
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 8 },
            mobile: { cols: 2 },
          }}
        >
          {items.map(partner => {
            return (
              <GridItem
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 4 },
                  mobile: { col: 1 },
                }}
                key={partner.id}
              >
                <ClientCard {...partner} routePath={path(partner)} />
                {/* <a href={partner.link} target="_blank" title={partner.title} rel="noreferrer">
                  {partner.logo && <Picture {...partner.logo} />}
                </a> */}
              </GridItem>
            );
          })}
        </Grid>
      </SiteBlock>
    );
  }
};

export const renderContentPartners = ({ items }) => {
  if (items.length) {
    return (
      <SiteBlock className="Article-ContentPartners" useMargin topGap="3xl">
        <Grid
          breakpoints={{
            desktop: { cols: 12 },
            tablet: { cols: 8 },
            mobile: { cols: 2 },
          }}
        >
          {items.map(partner => {
            return (
              <GridItem
                breakpoints={{
                  desktop: { col: 3 },
                  tablet: { col: 4 },
                  mobile: { col: 1 },
                }}
                key={partner.id}
              >
                <a
                  href={partner.link}
                  target="_blank"
                  title={partner.title}
                  rel="noreferrer"
                >
                  {partner.logo && <Picture {...partner.logo} />}
                  {partner.picture && <Picture {...partner.picture} />}
                </a>
              </GridItem>
            );
          })}
        </Grid>
      </SiteBlock>
    );
  }
};

export const renderContentPostsSecondary = ({ items, path }) => {
  if (items.length && path) {
    return (
      <Grid
        breakpoints={{
          desktop: { cols: 1, gap: 'xl' },
          tablet: { cols: 2, colGap: 'm', rowGap: '4xl' },
          mobile: { cols: 1, gap: '4xl' },
        }}
        className="Article-ContentPosts"
      >
        {items.map(post => (
          <GridItem col="1" key={post.id + post.title}>
            <EssenceCard
              {...post}
              view="filled"
              direction="row"
              path={path(post)}
            />
          </GridItem>
        ))}
      </Grid>
    );
  }
};

export const renderContentBanner = ({
  title,
  link,
  linkTitle,
  targetBlank,
  picture,
}) => {
  const buttonAttrs = prepareButtonAttrs(link, linkTitle, targetBlank);
  return (
    <Banner
      className="Article-Banner"
      view="landing"
      inverted
      title={title}
      picture={picture}
      buttonIcon={IconOpenInNew}
      buttonIconSize="xs"
      labelButton={linkTitle}
      buttonAttrs={buttonAttrs}
    />
  );
};

export const renderContentVacancy = ({ items, title, path }) => {
  if (items?.length) {
    return (
      <SiteBlock
        className="Article-Vacancy"
        useMargin
        topGap="3xl"
        bottomGap="xxl"
      >
        {title && (
          <Typography.Text
            size="xs"
            weight="bold"
            transform="uppercase"
            className="Article-Vacancy-Title"
          >
            {title}
          </Typography.Text>
        )}
        <Grid cols="1">
          {items.map(item => {
            return (
              <GridItem key={item.id}>
                <VacancyCard
                  {...item}
                  path={
                    item.slug ? generatePath(path, { slug: item.slug }) : null
                  }
                />
              </GridItem>
            );
          })}
        </Grid>
      </SiteBlock>
    );
  }
};

export const renderScrollButton = ({ item }) => {
  if (item) {
    return (
      <SiteBlock topGap="xxl" bottomGap="xxl" useMargin>
        <CtaScrollerBtn {...item} />
      </SiteBlock>
    );
  }
};
